import { useStyletron } from "baseui";
import { Avatar as BaseAvatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import React, { MouseEvent } from "react";
import { useHistory } from "react-router";
import stc from "string-to-color";
import { StyleObject } from "styletron-standard";
import {
  ArrowsLeftRight,
  Book,
  Briefcase,
  Building,
  BuildingBank,
  Cash,
  Check,
  ChevronsRight,
  Circle,
  CircleCheck,
  CircleX,
  Click,
  Cloud,
  CurrencyDollar,
  CurrencyZloty,
  DeviceLaptop,
  Divide,
  ExternalLink,
  Eye,
  EyeOff,
  FileBarcode,
  FileDescription,
  Folders,
  Friends,
  Ghost,
  HandGrab,
  Key,
  List,
  Lock,
  LockOpen,
  Mail,
  Mailbox,
  Man,
  Minus,
  Paperclip,
  Pin,
  PinnedOff,
  Point,
  ReportMoney,
  Send,
  Shield,
  Users,
  Wallet,
  World,
  X,
} from "tabler-icons-react";

import { DOCUMENT_STATES, ENADAWCA_PARCELS } from "../constants";
import { AssignmentAssessmentResult } from "../containers/Documents/documents.d";
import { HistoryFlowStepDocumentFlowRecipients } from "../containers/Flow/document-flow";
import { AssignmentStatus } from "../containers/Folders/folders.d";
import { OrganizationalUnit } from "../containers/OrganizationalUnits/organizational-units";
import { User } from "../containers/Users/users";
import { useAssignment } from "../contexts/assignment-context";
import { useDictionaries } from "../contexts/dictionaries-context";
import { checkPermission } from "../utils/check-permission";
import { PERMISSIONS } from "../utils/permissions";
import Tooltip from "./tooltip";

type Props = {
  dataType?: string;
  data?: string | number;
  children?: number | string | boolean | JSON | null;
  $style?: StyleObject;
  textWhenEmpty?: string;
  currency?: string;
  href?: string;
  loadingIfUndefined?: boolean;
  additionalData?: any;
  showBlankWhenEmpty?: boolean;
  inline?: boolean;
};

export default function FormattedValue({
  dataType,
  data,
  children,
  textWhenEmpty,
  currency,
  $style,
  href,
  loadingIfUndefined,
  additionalData,
  showBlankWhenEmpty,
  inline,
}: Props): React.ReactElement | null {
  const [css, theme] = useStyletron();
  const { countryCodes } = useDictionaries();
  const history = useHistory();

  const { setAssignment, setIsAssignmentActive } = useAssignment();

  const hasPermission =
    dataType?.includes("model:") &&
    ((dataType?.includes("roles") && checkPermission(PERMISSIONS.role.read)) ||
      (dataType?.includes("users") && checkPermission(PERMISSIONS.user.read)) ||
      (dataType?.includes("organizational-units") &&
        checkPermission(PERMISSIONS.organizationalUnit.read)) ||
      (dataType?.includes("positions") &&
        checkPermission(PERMISSIONS.position.read)) ||
      (dataType?.includes("jrwa-classifications") &&
        checkPermission(PERMISSIONS.jrwa.read)) ||
      (dataType?.includes("dictionaries") &&
        checkPermission(PERMISSIONS.dictionary.read)) ||
      (dataType?.includes("documents") &&
        (checkPermission(PERMISSIONS.document.read) ||
          checkPermission(PERMISSIONS.document.readAll))) ||
      (dataType?.includes("payments") &&
        checkPermission(PERMISSIONS.payments.read)) ||
      (dataType?.includes("cases") && checkPermission(PERMISSIONS.case.read)) ||
      (dataType?.includes("addresses") &&
        checkPermission(PERMISSIONS.address.read)) ||
      (dataType?.includes("flow") &&
        checkPermission(PERMISSIONS.documentFlow.read)) ||
      (dataType?.includes("shipment-contracts") &&
        checkPermission(PERMISSIONS.shipmentContract.read)) ||
      (dataType?.includes("document-parcels") &&
        checkPermission(PERMISSIONS.documentParcel.read)) ||
      (dataType?.includes("financial-plans") &&
        checkPermission(PERMISSIONS.financialPlan.read)) ||
      (dataType?.includes("groups") &&
        checkPermission(PERMISSIONS.group.read)) ||
      (dataType?.includes("currencies") &&
        checkPermission(PERMISSIONS.currency.read)) ||
      (dataType?.includes("barcodes") &&
        checkPermission(PERMISSIONS.barcode.read)) ||
      (dataType?.includes("internal-accounts") &&
        checkPermission(PERMISSIONS.internalAccounts.read)) ||
      (dataType?.includes("document-pickups") &&
        checkPermission(PERMISSIONS.documentPickup.read)) ||
      (dataType?.includes("divisors") &&
        (checkPermission(PERMISSIONS.divisorItems.read) ||
          checkPermission(PERMISSIONS.divisorTemplates.read))) ||
      (dataType?.includes("bank-accounts") &&
        checkPermission(PERMISSIONS.bankAccount.read)));

  if (showBlankWhenEmpty) {
    if (
      children === undefined ||
      children === "undefined undefined" ||
      children === "undefined" ||
      children === null ||
      children === ""
    )
      return null;
  }

  if (dataType === "system")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          color: "#999999",
        })}
      >
        <Ghost
          color="#999999"
          size={18}
          className={css({ marginRight: "5px", flexShrink: 0 })}
        />
        System
      </div>
    );

  if (dataType === "account-type")
    return (
      <Block display="flex" alignItems="center">
        {children === "IBAN" ? <World size={16} /> : <Circle size={16} />}

        <Block marginLeft="10px">{children === "IBAN" ? "IBAN" : "Inny"}</Block>
      </Block>
    );

  if (dataType === "button-style")
    return (
      <Block display="flex" alignItems="center">
        {children === "positive" ? (
          <Check size={16} />
        ) : children === "negative" ? (
          <X size={16} />
        ) : (
          <Send size={16} />
        )}

        <Block marginLeft="10px">
          {children === "positive"
            ? "Pozytywny"
            : children === "negative"
            ? "Negatywny"
            : "Neutralny"}
        </Block>
      </Block>
    );

  if (
    children === undefined ||
    children === "undefined undefined" ||
    children === "undefined" ||
    children === null ||
    children === "" ||
    (Array.isArray(children) && children.length === 0)
  )
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          color: "#999999",
          ...$style,
        })}
      >
        {loadingIfUndefined ? (
          <Skeleton height="14px" width="120px" animation />
        ) : (
          textWhenEmpty || (
            <>
              <Minus
                color="#999999"
                size={18}
                className={css({ marginRight: "5px", flexShrink: 0 })}
              />
              Brak
            </>
          )
        )}
      </div>
    );

  if (dataType === "login")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        <Key size={18} className={css({ marginRight: "5px", flexShrink: 0 })} />
        {children}
      </div>
    );

  if (dataType === "countryCode")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        {`${
          countryCodes.find((dV) => dV.value === children)?.name
        } (${children})`}
      </div>
    );

  if (dataType === "nip")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        {children}
      </div>
    );

  if (dataType === "document-kind")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        {children === "Incoming"
          ? "Przychodzący"
          : children === "Outgoing"
          ? "Wychodzący"
          : "Wewnętrzny"}
      </div>
    );

  if (dataType === "document-state")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        {children === DOCUMENT_STATES.New
          ? "Nowy"
          : children === DOCUMENT_STATES.InProgress
          ? "W trakcie obiegu"
          : children === DOCUMENT_STATES.Accepted
          ? "Zaakceptowany"
          : children === DOCUMENT_STATES.Rejected
          ? "Odrzucony"
          : children === DOCUMENT_STATES.Canceled
          ? "Anulowany"
          : children === DOCUMENT_STATES.Closed
          ? "Zamknięty"
          : children === DOCUMENT_STATES.Voided
          ? "Unieważniony"
          : "Nieznany"}
      </div>
    );

  if (dataType === "assignment-status")
    return (
      <span>
        {children === AssignmentStatus.Finished ? (
          <Circle
            size={8}
            fill={theme.colors.positive}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : children === AssignmentStatus.InProgress ? (
          <Circle
            size={8}
            fill={theme.colors.primary}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.primary,
            })}
          />
        ) : (
          <Circle
            size={8}
            fill={theme.colors.negative}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
        {children === AssignmentStatus.Finished
          ? "Zakończone"
          : children === AssignmentStatus.InProgress
          ? "W realizacji"
          : "Nowe"}
      </span>
    );

  if (dataType === "assignment-assessment-result")
    return (
      <span>
        {children === AssignmentAssessmentResult.Accept ? (
          <CircleCheck
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : children === AssignmentAssessmentResult.Reject ? (
          <CircleX
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        ) : null}
        {children === AssignmentAssessmentResult.Accept
          ? "Zaakceptowano"
          : children === AssignmentAssessmentResult.Reject
          ? "Odrzucono"
          : "N/D"}
      </span>
    );

  if (dataType === "flowStepResult")
    return (
      <div
        className={css({
          color:
            children === "Odrzucenie"
              ? theme.colors.negative
              : theme.colors.positive,
        })}
      >
        {children}
      </div>
    );

  if (dataType === "price") return <>{children} PLN</>;
  if (dataType === "dimensions") return <>{children} mm</>;
  if (dataType === "weight") return <>{children} g</>;
  if (dataType === "eNadawcaShipmentType")
    return (
      <>
        {
          ENADAWCA_PARCELS.find(
            (parcel: { id: string }) => parcel.id === children
          )?.label
        }
      </>
    );

  if (dataType === "pre")
    return (
      <span
        className={css({
          marginTop: "0px",
          marginBottom: "0px",
          fontWeight: 400,
          fontFamily: "monospace",
          fontSize: "14px",
          backgroundColor: theme.colors.backgroundSecondary,
          paddingLeft: theme.sizing.scale300,
          paddingRight: theme.sizing.scale300,
          paddingTop: theme.sizing.scale100,
          paddingBottom: theme.sizing.scale100,
          display: "inline-block",
          borderRadius: theme.borders.radius100,
          ...$style,
        })}
      >
        {children}
      </span>
    );
  else if (dataType === "email")
    return (
      <StyledLink
        target="_blank"
        href={`mailto:${children}`}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <Mail
          size={14}
          className={css({
            verticalAlign: "middle",
            marginRight: "10px",
            display: "inline",
          })}
        />
        {children}
      </StyledLink>
    );
  else if (dataType?.includes("model:cases"))
    return (
      <StyledLink
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          setAssignment(undefined);
          setIsAssignmentActive(false);
          history.push(`/cases/${data}`);
        }}
        href={`/cases/${data}`}
        $style={{
          color: `${
            additionalData ? theme.colors.positive : theme.colors.negative
          } !important`,
        }}
      >
        <div
          className={css({
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "5px",
            width: "max-content",
          })}
        >
          <span
            className={css({
              whiteSpace: "nowrap",
            })}
          >
            {children}
          </span>

          <Tooltip
            content={
              additionalData
                ? "Sprawa prowadzona elektronicznie"
                : "Sprawa prowadzona tradycyjnie"
            }
          >
            <span>
              {additionalData ? (
                <Cloud
                  size={14}
                  className={css({
                    verticalAlign: "middle",
                    display: "inline",
                    color: theme.colors.positive,
                  })}
                />
              ) : (
                <Paperclip
                  size={14}
                  className={css({
                    verticalAlign: "middle",
                    display: "inline",
                    color: theme.colors.negative,
                  })}
                />
              )}
            </span>
          </Tooltip>
        </div>
      </StyledLink>
    );
  else if (dataType?.includes("model:"))
    return (
      <StyledLink
        {...(hasPermission &&
          data && {
            href: href || `/${dataType.split(":")[1]}/${data}`,
            onClick: (event: MouseEvent) => {
              event.preventDefault();
              history.push(href || `/${dataType.split(":")[1]}/${data}`);
            },
          })}
        $style={{
          ...(hasPermission && data
            ? {
                textDecoration: "underline dotted",
              }
            : {
                textDecoration: "none",
                ":hover": {
                  color: "inherit",
                },
              }),
          fontWeight: 400,
          ...(!hasPermission && {
            cursor: "not-allowed",
          }),
          ...$style,
        }}
      >
        {dataType?.includes("roles") ? (
          <Shield
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("users") ? (
          <BaseAvatar
            name={children as string}
            size={"16px"}
            overrides={{
              Root: {
                style: {
                  backgroundColor: stc(children as string),
                  verticalAlign: "middle",
                  marginRight: "10px",
                  display: "inline-block",
                },
              },
              Initials: {
                style: {
                  fontSize: "8px",
                },
              },
            }}
          />
        ) : dataType?.includes("organizational-units") ? (
          <Building
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("groups") ? (
          <Users
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("positions") ? (
          <Friends
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("jrwa-classifications") ? (
          <List
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("dictionaries") ? (
          <Book
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("documents") ? (
          <Briefcase
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("payments") ? (
          <Cash
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("cases") ? (
          <Folders
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("addresses") ? (
          <Mailbox
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("flow") ? (
          <ArrowsLeftRight
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("shipment-contracts") ? (
          <CurrencyZloty
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("document-parcels") ? (
          <Mail
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("document-pickups") ? (
          <HandGrab
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("currencies") ? (
          <CurrencyDollar
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("internal-accounts") ? (
          <Wallet
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("divisors") ? (
          <Divide
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("bank-accounts") ? (
          <BuildingBank
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("financial-plans") ? (
          <ReportMoney
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : dataType?.includes("barcodes") ? (
          <FileBarcode
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : (
          <ExternalLink
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        )}
        {children}
      </StyledLink>
    );
  else if (dataType?.includes("flow-action"))
    return (
      <StyledLink
        href={`${data}`}
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          history.push(`${data}`);
        }}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <Click
          size={16}
          className={css({
            verticalAlign: "middle",
            marginRight: "10px",
            display: "inline",
          })}
        />
        {children}
      </StyledLink>
    );
  else if (dataType?.includes("flow-end-step"))
    return (
      <StyledLink
        href={`${data}`}
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          history.push(`${data}`);
        }}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <ChevronsRight
          size={16}
          className={css({
            verticalAlign: "middle",
            marginRight: "10px",
            display: "inline",
          })}
        />
        {children}
      </StyledLink>
    );
  else if (dataType?.includes("flow-step"))
    return (
      <StyledLink
        href={`${data}`}
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          history.push(`${data}`);
        }}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <Point
          size={16}
          className={css({
            verticalAlign: "middle",
            marginRight: "10px",
            display: "inline",
          })}
        />
        {children}
      </StyledLink>
    );
  else if (dataType === "inverted-boolean")
    return (
      <>
        {children ? (
          <CircleX
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <CircleCheck
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
      </>
    );
  else if (dataType === "case-conduct-boolean")
    return (
      <>
        {children ? (
          <Cloud
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <Paperclip
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Elektronicznie" : "Tradycyjnie"}
      </>
    );
  else if (dataType === "electronical-boolean")
    return (
      <>
        {children ? (
          <DeviceLaptop
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <FileDescription
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Elektroniczna" : "Papierowa"}
      </>
    );
  else if (dataType === "access-boolean")
    return (
      <>
        {children ? (
          <Lock
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <LockOpen
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
      </>
    );
  else if (dataType === "personal-data-boolean")
    return (
      <>
        {children ? (
          <Man
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <Building
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
      </>
    );
  else if (dataType === "visibility-boolean")
    return (
      <>
        {children ? (
          <EyeOff
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <Eye
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Nie" : "Tak"}
      </>
    );
  else if (dataType === "activity-boolean")
    return (
      <>
        {children ? (
          <Pin
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <PinnedOff
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Proces aktywny" : "Proces nieaktywny"}
      </>
    );
  else if (dataType === "boolean")
    return (
      <>
        {children ? (
          <CircleCheck
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <CircleX
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
      </>
    );
  if (dataType === "datetime")
    return (
      <span className={css({ ...$style })}>
        {new Date(String(children)).toLocaleString()}
      </span>
    );

  if (dataType === "datetime-relative")
    return (
      <Tooltip
        content={formatDistanceToNow(new Date(String(children)), {
          locale: pl,
          addSuffix: true,
        })}
      >
        <span className={css({ ...$style })}>
          {new Date(String(children)).toLocaleString()}
        </span>
      </Tooltip>
    );

  if (dataType === "quota")
    return (
      <span className={css({ ...$style })}>
        {new Intl.NumberFormat("pl-PL", {
          style: "currency",
          currencyDisplay: "code",
          currency: currency || "PLN",
        }).format(parseFloat(children as string))}
      </span>
    );

  if (dataType === "exchange-rate")
    return (
      <span className={css({ ...$style })}>
        {new Intl.NumberFormat("pl-PL", {
          style: "currency",
          currencyDisplay: "code",
          currency: "PLN",
          minimumFractionDigits: 4,
        }).format(parseFloat(children as string))}
      </span>
    );

  if (dataType === "pixels")
    return <span className={css({ ...$style })}>{children} px</span>;

  if (dataType === "notification-content")
    return children ? (
      <span>(został) {children}</span>
    ) : (
      <span>(został) przeniesiony</span>
    );

  if (dataType === "sending-list-status")
    return (
      <Block display="flex" alignItems="center">
        {children ? (
          <CircleCheck
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <CircleX
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Przyjęta" : "Brak"}
      </Block>
    );

  if (dataType === "payment-type")
    return (
      <>
        {children === "PrivateCreditCard"
          ? "Karta kredytowa prywatna"
          : children === "CorporateCreditCard"
          ? "Karta kredytowa służbowa"
          : children === "Transfer"
          ? "Przelew"
          : children === "Cash"
          ? "Gotówka"
          : ""}
      </>
    );

  if (dataType === "action-result")
    return (
      <>
        {children === "New"
          ? "Nowy"
          : children === "InProgress"
          ? "W obiegu"
          : children === "Accepted"
          ? "Zaakceptowany"
          : children === "Rejected"
          ? "Odrzucony"
          : children === "Canceled"
          ? "Anulowany"
          : ""}
      </>
    );

  if (dataType === "document-pickup-state")
    return (
      <>
        {children === "New"
          ? "Nowe"
          : children === "PickupInProgress"
          ? "Wysłano kod"
          : children === "PickupConfirmed"
          ? "Potwierdzone kodem"
          : children === "ReturnInProgress"
          ? "W trakcie zwrotu"
          : children === "ReturnConfirmed"
          ? "Potwierdzone kodem"
          : ""}
      </>
    );

  if (dataType?.startsWith("json:")) {
    if (dataType === "json:organizational-units") {
      return (
        <Block
          display="flex"
          flexDirection={inline ? "row" : "column"}
          gridRowGap="6px"
          gridColumnGap="12px"
        >
          {((children as unknown) as Pick<
            OrganizationalUnit,
            "name" | "symbol" | "budgetSymbol"
          >[])?.map(({ name }, index) => (
            <div
              className={css({ display: "flex", alignItems: "center" })}
              key={index}
            >
              <Building
                size={16}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  display: "inline",
                  flexShrink: 0,
                })}
              />

              <span>{name}</span>

              {inline
                ? index === ((children as unknown) as []).length - 1
                  ? ""
                  : ","
                : null}
            </div>
          ))}
        </Block>
      );
    }

    if (dataType === "json:users") {
      return (
        <Block
          display="flex"
          flexDirection={inline ? "row" : "column"}
          gridRowGap="6px"
          gridColumnGap="12px"
        >
          {((children as unknown) as Pick<
            User,
            "firstName" | "lastName"
          >[])?.map(({ firstName, lastName }, index) => (
            <div
              className={css({ display: "flex", alignItems: "center" })}
              key={index}
            >
              <BaseAvatar
                name={`${firstName} ${lastName}`}
                size={"16px"}
                overrides={{
                  Root: {
                    style: {
                      backgroundColor: stc(`${firstName} ${lastName}`),
                      verticalAlign: "middle",
                      marginRight: "10px",
                      display: "inline-block",
                    },
                  },
                  Initials: {
                    style: {
                      fontSize: "8px",
                    },
                  },
                }}
              />

              <span>{`${firstName} ${lastName}`}</span>

              {inline
                ? index === ((children as unknown) as []).length - 1
                  ? ""
                  : ","
                : null}
            </div>
          ))}
        </Block>
      );
    }

    if (dataType === "json:attachments") {
      const items = (children as unknown) as { originalName: string }[];
      return (
        <Block display="flex" flexDirection="column">
          <span className={css({ fontSize: "12px" })}>
            Dodano {items?.length}:
          </span>
          {items?.map(({ originalName }, index) => (
            <div
              className={css({
                marginTop: "0px",
                marginBottom: "0px",
                fontWeight: 400,
                fontFamily: "monospace",
                fontSize: "14px",
                padding: "2px 0",
                display: "inline-block",
                ...$style,
              })}
              key={`${originalName}-${index}`}
            >
              - {originalName}
            </div>
          ))}
        </Block>
      );
    }

    if (dataType === "json:users-with-organizational-units") {
      return (
        <Block
          display="flex"
          flexDirection={inline ? "row" : "column"}
          gridRowGap="6px"
          gridColumnGap="12px"
        >
          {((children as unknown) as HistoryFlowStepDocumentFlowRecipients["users"])?.map(
            ({ firstName, lastName, organizationalUnits }, index) => (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                })}
                key={index}
              >
                <BaseAvatar
                  name={`${firstName} ${lastName}`}
                  size={"16px"}
                  overrides={{
                    Root: {
                      style: {
                        backgroundColor: stc(`${firstName} ${lastName}`),
                        verticalAlign: "middle",
                        marginRight: "10px",
                        display: "inline-block",
                      },
                    },
                    Initials: {
                      style: {
                        fontSize: "8px",
                      },
                    },
                  }}
                />

                <Tooltip
                  content={() => (
                    <FormattedValue dataType="json:organizational-units">
                      {(organizationalUnits as unknown) as JSON}
                    </FormattedValue>
                  )}
                >
                  <span>{`${firstName} ${lastName}`}</span>
                </Tooltip>

                {inline
                  ? index === ((children as unknown) as []).length - 1
                    ? ""
                    : ","
                  : null}
              </div>
            )
          )}
        </Block>
      );
    }
  }

  if (dataType === "date")
    return <>{new Date(String(children)).toLocaleDateString()}</>;
  else
    return <span {...($style && { className: css($style) })}>{children}</span>;
}
