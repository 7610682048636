import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { DOCUMENT_TYPENAMES } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { ACTIVITY_LOGS_FIELDS } from "../activity-logs.form";
import { ACTIVITY_LOGS_SHOW } from "../activity-logs.gql";

export default function ActivityLogsShow(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(ACTIVITY_LOGS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.activityLog) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.activityLog && setIsFetching(false), [data]);

  return (
    <article>
      <Header
        title={`Log ${data?.activityLog?.id}`}
        labels={["Logi aktywności", "Wyświetlanie"]}
        goBackOption
      />
      <Content>
        <Grid>
          {ACTIVITY_LOGS_FIELDS.filter(
            (g) =>
              g.fields.filter((f) => f.show.visible).length > 0 &&
              ((g.accessor && data?.activityLog[g.accessor]) || !g.accessor)
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter(
                (f) =>
                  f.show.visible &&
                  ((f.typeName &&
                    f.typeName === data?.activityLog?.subject?.__typename) ||
                    !f.typeName)
              )
              .map((item, index) => {
                if (
                  (item.id === "assignmentUsers" &&
                    (data?.activityLog?.activity === "dispatched" ||
                      data?.activityLog?.activity === "shared")) ||
                  (item.id === "attachmentData" &&
                    data?.activityLog?.attachmentData &&
                    !!Object.keys(data?.activityLog?.attachmentData).length) ||
                  (item.id !== "assignmentUsers" &&
                    item.id !== "attachmentData")
                )
                  return (
                    <Cell
                      span={item.span || 6}
                      key={group.id + `-field` + index}
                    >
                      <FormControl label={item.label} disabled={true}>
                        {isFetching ? (
                          <Skeleton
                            rows={0}
                            height="20px"
                            width="100%"
                            animation
                          />
                        ) : item.typeName ? (
                          item.typeName === "User" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={data?.activityLog?.subject?.id}
                            >
                              {`${data?.activityLog?.subject?.firstName} ${data?.activityLog?.subject?.lastName}`}
                            </FormattedValue>
                          ) : item.typeName === "Dictionary" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={data?.activityLog?.subject?.id}
                            >
                              {data?.activityLog?.subject?.name}
                            </FormattedValue>
                          ) : item.typeName === "DictionaryValue" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={
                                item.show.accessor
                                  ? data?.activityLog[item.show.accessor[0]]?.[
                                      item.show.accessor[1]
                                    ]
                                  : data?.activityLog[item.id]
                              }
                            >
                              {`${data?.activityLog?.subject?.name} (słownik: ${data?.activityLog?.subject?.dictionary?.name})`}
                            </FormattedValue>
                          ) : item?.typeName === "Case" ? (
                            <FormattedValue
                              dataType={item?.dataType}
                              data={data?.activityLog?.subject?.id}
                              additionalData={
                                data?.activityLog?.subject
                                  ?.isConductedElectronically
                              }
                            >
                              {`[${data?.activityLog?.subject?.number}] ${data?.activityLog?.subject?.name}`}
                            </FormattedValue>
                          ) : item.typeName === DOCUMENT_TYPENAMES.Document ||
                            item.typeName ===
                              DOCUMENT_TYPENAMES.ContractAgreement ||
                            item.typeName === DOCUMENT_TYPENAMES.RequestForm ||
                            item.typeName ===
                              DOCUMENT_TYPENAMES.FinancialAccountingDocument ||
                            item.typeName ===
                              DOCUMENT_TYPENAMES.ContractPreparationRequest ||
                            item.typeName ===
                              DOCUMENT_TYPENAMES.BillIssuanceRequest ||
                            item.typeName ===
                              DOCUMENT_TYPENAMES.RequestFormNote ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.subject?.documentKind?.toLowerCase()}/${
                                data?.activityLog?.subject?.type
                              }/${data?.activityLog?.subject?.id}`}
                            >
                              {data?.activityLog?.subject?.internalNumber}
                            </FormattedValue>
                          ) : item.typeName === "BudgetItem" ? (
                            <FormattedValue>
                              {`Pozycja budżetu ${data?.activityLog?.subject?.budget?.name}`}
                            </FormattedValue>
                          ) : item.typeName === "RequestFormItem" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.subject?.requestForm?.documentKind?.toLowerCase()}/${
                                data?.activityLog?.subject?.requestForm?.type
                              }/${data?.activityLog?.subject?.requestForm?.id}`}
                            >
                              {`Pozycja wniosku ${data?.activityLog?.subject?.requestForm?.internalNumber}`}
                            </FormattedValue>
                          ) : item.typeName === "BankAccount" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={data?.activityLog?.subject?.address?.id}
                            >
                              {`Konto bankowe adresu ${data?.activityLog?.subject?.address?.name}`}
                            </FormattedValue>
                          ) : item.typeName === "DocumentFlowStep" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.subject?.documentFlow?.id}/step/${data?.activityLog?.subject?.id}`}
                            >
                              {data?.activityLog?.subject?.name}
                            </FormattedValue>
                          ) : item.typeName === "File" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.properties?.document?.documentKind?.toLowerCase()}/${
                                data?.activityLog?.properties?.document?.type
                              }/${data?.activityLog?.properties?.document?.id}`}
                            >
                              {`Załączniki dokumentu ${data?.activityLog?.properties?.document?.internalNumber}`}
                            </FormattedValue>
                          ) : item.typeName === "ShipmentRate" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.subject?.shipmentContractId}/${data?.activityLog?.subject?.id}`}
                            >
                              {data?.activityLog?.subject?.name}
                            </FormattedValue>
                          ) : item.typeName === "DocumentParcel" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={data?.activityLog?.subject?.id}
                            >
                              {`List nadany dokumentu ${data?.activityLog?.subject?.document?.internalNumber}`}
                            </FormattedValue>
                          ) : item.typeName === "DocumentPickup" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={data?.activityLog?.subject?.id}
                            >
                              {`Wydanie dokumentów numer ${data?.activityLog?.subject?.id}`}
                            </FormattedValue>
                          ) : item.typeName ===
                            "FinancialAccountingDocumentItem" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.subject?.financialAccountingDocument?.documentKind?.toLowerCase()}/${
                                data?.activityLog?.subject
                                  ?.financialAccountingDocument?.type
                              }/${data?.activityLog?.subject?.requestForm?.id}`}
                            >
                              {`Pozycja ${data?.activityLog?.subject?.financialAccountingDocument?.internalNumber}`}
                            </FormattedValue>
                          ) : item.typeName === "DocumentFlowAction" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.subject?.documentFlowId}/action/${data?.activityLog?.subject?.id}`}
                            >
                              {data?.activityLog?.subject?.label}
                            </FormattedValue>
                          ) : item.typeName === "DivisorItem" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.subject?.divisorTemplateId}/${data?.activityLog?.subject?.id}`}
                            >
                              {data?.activityLog?.subject?.name}
                            </FormattedValue>
                          ) : item.typeName === "PaymentItem" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.subject?.paymentId}`}
                            >
                              {`Pozycja polecenia płatności ${data?.activityLog?.subject?.payment?.internalNumber}`}
                            </FormattedValue>
                          ) : item.typeName === "Barcode" ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={`${data?.activityLog?.subject?.id}`}
                            >
                              Kod kreskowy
                            </FormattedValue>
                          ) : (
                            <FormattedValue
                              dataType={
                                item.dataType === "model:documents"
                                  ? `${
                                      item.dataType
                                    }/${data?.activityLog?.subject?.documentKind?.toLowerCase()}/${
                                      data?.activityLog?.subject?.type
                                    }`
                                  : item.dataType
                              }
                              data={data?.activityLog?.subject?.id}
                            >
                              {item.show.accessor
                                ? data?.activityLog[item.show.accessor[0]]?.[
                                    item.show.accessor[1]
                                  ]
                                : data?.activityLog[item.id]}
                            </FormattedValue>
                          )
                        ) : item.id === "user" ? (
                          data?.activityLog?.user?.id === undefined ? (
                            data?.activityLog?.properties?.login ? (
                              <FormattedValue dataType="login">
                                {data?.activityLog?.properties?.login}
                              </FormattedValue>
                            ) : (
                              <FormattedValue dataType="system"></FormattedValue>
                            )
                          ) : data?.activityLog?.substituter ? (
                            <div
                              className={css({
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              })}
                            >
                              <FormattedValue
                                dataType={"model:users"}
                                data={data?.activityLog?.substituter?.id}
                              >
                                {`${data?.activityLog?.substituter?.firstName} ${data?.activityLog?.substituter?.lastName}`}
                              </FormattedValue>

                              <span>w zastępstwie za</span>
                              <FormattedValue
                                dataType={"model:users"}
                                data={data?.activityLog?.user?.id}
                              >
                                {`${data?.activityLog?.user?.firstName} ${data?.activityLog?.user?.lastName}`}
                              </FormattedValue>
                            </div>
                          ) : (
                            <FormattedValue
                              dataType={"model:users"}
                              data={data?.activityLog?.user?.id}
                            >
                              {`${data?.activityLog?.user?.firstName} ${data?.activityLog?.user?.lastName}`}
                            </FormattedValue>
                          )
                        ) : item.id === "ipAddress" ||
                          item.id === "operatingSystem" ||
                          item.id === "browserName" ||
                          item.id === "browserVersion" ? (
                          data?.activityLog?.properties?.clientInfo ? (
                            <FormattedValue
                              dataType={item.dataType}
                              data={data?.activityLog[item.id]}
                            >
                              {
                                data?.activityLog?.properties?.clientInfo[
                                  item.id
                                ]
                              }
                            </FormattedValue>
                          ) : (
                            <FormattedValue
                              dataType={item.dataType}
                              data={data?.activityLog[item.id]}
                            >
                              {item.show.accessor
                                ? data?.activityLog[item.show.accessor[0]]?.[
                                    item.show.accessor[1]
                                  ]
                                : data?.activityLog[item.id]}
                            </FormattedValue>
                          )
                        ) : (
                          // JRWA, OrganizationalUnit, Position, Role, Address, BudgetCategory, DocumentFlow, ShipmentContract, Currency
                          // Payment, InternalAccount, DivisorTemplate, FinancialPlan
                          <FormattedValue
                            dataType={item.dataType}
                            data={data?.activityLog[item.id]}
                          >
                            {item.show.accessor
                              ? data?.activityLog[item.show.accessor[0]]?.[
                                  item.show.accessor[1]
                                ]
                              : data?.activityLog[item.id]}
                          </FormattedValue>
                        )}
                      </FormControl>
                    </Cell>
                  );
              }),
          ])}
        </Grid>
      </Content>
    </article>
  );
}
