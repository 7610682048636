import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import FilesList from "../../../../components/files-list";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledCasesSelect,
  ControlledDictionaryValuesSelect,
  ControlledDocumentsSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledOrganizationalUnitUsersSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import { HIDDEN_FILE } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { useFormFieldDisable } from "../../../../utils/hooks/useFormFieldDisable";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { AssignmentLocationState } from "../../../Folders/folders";
import { DocumentLink } from "../../documents";
import { DocumentInputs, DOCUMENTS_FIELDS } from "../../documents.form";
import {
  DOCUMENT_FILES_SHOW,
  DOCUMENTS_SHOW,
  DOCUMENTS_UPDATE,
  FILES_REVEAL,
} from "../../documents.gql";

export default function DocumentsEditInternal(): React.ReactElement {
  const [
    isFilesRevealConfirmDialogOpen,
    setIsFilesRevealConfirmDialogOpen,
  ] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
  const { user } = useAuth();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm<DocumentInputs>();

  const watchRecipientWhenUser = watch("recipientWhenUser");
  const watchOrganizationalUnit = watch("organizationalUnit");

  const { data, error: queryError } = useQuery(DOCUMENTS_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const {
    data: documentFilesData,
    refetch: documentFilesRefetch,
    error: documentFilesError,
  } = useQuery(DOCUMENT_FILES_SHOW, {
    variables: { id: documentId },
    fetchPolicy: "no-cache",
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasHiddenFiles =
    !!documentFilesData?.document?.files?.length &&
    documentFilesData?.document?.files[0]?.__typename === HIDDEN_FILE;
  const [updateDocument, { error: mutationError, loading }] = useMutation(
    DOCUMENTS_UPDATE,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    description,
    parentIds,
    documentDate,
    sequenceNumber,
    documentNumber,
    files,
    name,
    additionalCode,
    programName,
    title,
    raksDiaryNumber,
  }: DocumentInputs): Promise<void> => {
    setIsLoading(true);

    const { document } = data;

    try {
      await updateDocument({
        variables: {
          documentUpdateInput: {
            id: documentId,
            parentIds: parentIds?.length ? parentIds.map(({ id }) => id) : null,
            ...(documentNumber !== document?.documentNumber && {
              documentNumber,
            }),
            ...(documentDate &&
              new Date(documentDate as Date).toISOString() !==
                document?.documentDate && {
                documentDate,
              }),
            ...(description !== document?.description && {
              description,
            }),
            ...(sequenceNumber !== document?.sequenceNumber && {
              sequenceNumber,
            }),
            userId: user?.id,
            ...(name !== document?.name && {
              name,
            }),
            ...(additionalCode !== document?.additionalCode && {
              additionalCode,
            }),
            ...(programName !== document?.programName && {
              programName,
            }),
            ...(title !== document?.title && {
              title,
            }),
            ...(raksDiaryNumber !== document?.raksDiaryNumber && {
              raksDiaryNumber,
            }),
            deleteFilesIds: selectedFiles.length ? selectedFiles : null,
          },
          files,
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.goBack();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onFilesRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealFiles({
        variables: {
          documentId,
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      documentFilesRefetch();
      setIsFilesRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsFilesRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (documentFilesError?.graphQLErrors)
      enqueueSnackbar({
        message: (documentFilesError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [documentFilesError]);

  useEffect(() => {
    if (data?.document?.currentAssignment) {
      setAssignment(data?.document?.currentAssignment);
      setIsAssignmentActive(true);
    }
    if (data?.document) {
      setIsFetching(false);
      setValue("documentNumber", data?.document?.documentNumber);
      !!data?.document?.documentDate &&
        setValue("documentDate", new Date(data?.document?.documentDate));
      setValue("description", data?.document?.description);
      setValue("sequenceNumber", data?.document?.sequenceNumber);
      setValue("title", data?.document?.title);
      setValue("programName", data?.document?.programName);
      setValue("additionalCode", data?.document?.additionalCode);
      setValue("name", data?.document?.name);
      if (data?.document?.case)
        setValue("caseName", [
          {
            id: data?.document?.case?.id,
            label: `[${data?.document?.case?.number}] ${data?.document?.case?.name}`,
          },
        ]);
      if (data?.document?.documentLinks?.length)
        setValue(
          "parentIds",
          data?.document?.documentLinks
            ?.filter(
              ({ type, linkerId }: DocumentLink) =>
                type === "Parent" && parseInt(id as string) === linkerId
            )
            .map(({ linked, linker }: DocumentLink) =>
              parseInt(id as string) === linker.id ? linked : linker
            )
        );
    }
    if (data?.document?.organizationalUnit?.id)
      setValue("organizationalUnit", [
        {
          id: data?.document?.organizationalUnit?.id,
          label: `[
        ${data?.document?.organizationalUnit?.symbol}
      ] ${data?.document?.organizationalUnit?.name}`,
        },
      ]);
    if (data?.document?.recipient)
      setValue("recipientWhenUser", [
        {
          id: data?.document?.recipient?.id,
          label: `${data?.document?.recipient?.firstName} ${data?.document?.recipient?.lastName}`,
        },
      ]);

    if (data?.document?.documentType)
      setValue("documentType", [
        {
          id: data?.document?.documentType,
          label: data?.document?.documentType,
        },
      ]);
  }, [data]);

  useEffect(() => {
    if (errors) scrollOnError(errors, DOCUMENTS_FIELDS, "edit");
  }, [errorTimeStamp]);

  const shouldDisableField = useFormFieldDisable(data?.document);

  if (
    data &&
    data?.document &&
    (!checkPermission(PERMISSIONS.document.update) ||
      !data?.document?.isEditable)
  )
    <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.document?.internalNumber}
        labels={["Dokumenty", "Edytowanie"]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "editInternalDocument",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={4}>
            <FormControl label="Numer" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {data?.document?.internalNumber}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={8}>
            <FormControl label="Data rejestracji" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="datetime">
                  {data?.document?.createdAt}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="editInternalDocument" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {DOCUMENTS_FIELDS.filter(
              (g) =>
                g.fields.filter(
                  (f) => f.edit?.visible && f.typeName?.includes("Internal")
                ).length > 0 && g.id !== "sender"
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>
                          {!!data?.document?.files?.length &&
                          group.id === "files"
                            ? `${group.label} (${data?.document?.files?.length})`
                            : group.label}
                        </LabelMedium>
                        {hasHiddenFiles && group.id === "files" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsFilesRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealFilesLoading || isFetching}
                            type="button"
                          >
                            Pokaż pliki
                          </Button>
                        )}
                      </Block>
                      {!hasHiddenFiles &&
                        !!data?.document?.files?.length &&
                        group.id === "files" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "13px" }}
                          >
                            Dostęp jest aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter(
                  (f) => f.edit?.visible && f.typeName?.includes("Internal")
                )
                .map((item, index) => (
                  <Cell
                    span={item.span || 6}
                    skip={item.skip || 0}
                    key={group.id + `-field` + index}
                  >
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={
                        (item.id === "filesList" && hasHiddenFiles) ||
                        !data?.document?.files?.length
                          ? ""
                          : item.caption
                      }
                      required={item.edit.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) =>
                              item.id.includes("address")
                                ? vE?.property ===
                                  item.id.slice(7).toLowerCase()
                                : vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading || shouldDisableField(item.id)}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.UsersSelect ? (
                        <ControlledUsersSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.CasesSelect ? (
                        <ControlledCasesSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                        <ControlledOrganizationalUnitsSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.DocumentsSelect ? (
                        <ControlledDocumentsSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          filterOptions={(options) =>
                            options.filter((o) => o.id != id)
                          }
                          rules={{
                            ...(item.edit.required && {
                              required: formValidation.messages.required,
                            }),
                            validate: (value) => {
                              return value?.[0]?.id?.toString() === id
                                ? "Dokument nie może być nadrzędnym sam dla siebie"
                                : true;
                            },
                          }}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.OrganizationalUnitUsersSelect ? (
                        watchOrganizationalUnit &&
                        watchOrganizationalUnit.length > 0 ? (
                          <ControlledOrganizationalUnitUsersSelect
                            objectId={watchOrganizationalUnit[0].id}
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : (
                          <ControlledUsersSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        )
                      ) : item.type ===
                        FIELDS.UsersOrganizationalUnitsSelect ? (
                        watchRecipientWhenUser &&
                        watchRecipientWhenUser.length > 0 ? (
                          <ControlledUsersOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            objectId={watchRecipientWhenUser[0].id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : (
                          <ControlledOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        )
                      ) : item.type === FIELDS.DictionaryValuesSelect ? (
                        <ControlledDictionaryValuesSelect
                          dictionarySystemName={"documentTypes.internal"}
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={"Wybierz"}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.DatePicker ? (
                        <div
                          className={css({
                            position: "relative",
                          })}
                        >
                          <ControlledDatePicker
                            name={item.id}
                            control={control}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        </div>
                      ) : item.type === FIELDS.FilesPicker ? (
                        <ControlledFilesPicker
                          control={control}
                          name={item.id}
                          maxSize={134217728}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.FilesList ? (
                        <FilesList
                          files={documentFilesData?.document?.files}
                          hasHiddenFiles={hasHiddenFiles}
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={loading || shouldDisableField(item.id)}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          {item.label}
                        </ControlledCheckbox>
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={
                            shouldDisableField(item.id) ||
                            (item.id === "sequenceNumber" &&
                              !checkPermission(
                                PERMISSIONS.document.updateSequenceNumber
                              ))
                          }
                          masked={item.mask !== undefined}
                          mask={item.mask}
                          maskChar="_"
                          endEnhancer={item.endEnhancer}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji dokumentu przychodzącego"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isFilesRevealConfirmDialogOpen}
              type={ConfirmDialogType.FilesReveal}
              close={() => setIsFilesRevealConfirmDialogOpen(false)}
              confirm={() => onFilesRevealSubmit()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
