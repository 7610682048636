import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route as RouterRoute,
  RouteProps,
  useLocation,
} from "react-router-dom";

import { useAuth } from "../contexts/auth-context";
import Loading from "./loading";

type Props = {
  component: (() => React.ReactElement) | React.Component;
  $private?: boolean;
  $shared?: boolean;
  key?: string;
} & RouteProps;

const Route = ({
  component: Component,
  $private,
  $shared,
  key,
  ...props
}: Props): React.ReactElement => {
  const { hasToken } = useAuth();
  const { key: locationKey } = useLocation();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const timer = setTimeout(() => {
        setIsInitialized(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, []);

  if (!isInitialized) {
    return <Loading />;
  }

  return (
    <RouterRoute
      key={`${key}---${locationKey}`}
      {...props}
      render={(props) =>
        $shared ? (
          <Component {...props} />
        ) : $private ? (
          hasToken ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        ) : hasToken ? (
          <Redirect to={"/"} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default Route;
