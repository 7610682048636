import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  codeFormats: [{ id: string; label?: string }];
  codeFormat: string;
  width: string;
  height: string;
  stickerWidth: string;
  stickerHeight: string;
};

export const BARCODES_FIELDS: FieldsGroup[] = [
  {
    id: "barcode",
    label: "Parametry kodu kreskowego",
    fields: [
      {
        id: "width",
        label: "Szerokość kreski",
        type: FIELDS.NumberInput,
        dataType: "pixels",
        endEnhancer: "px",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: true, required: true },
      },
      {
        id: "height",
        label: "Wysokość",
        type: FIELDS.NumberInput,
        dataType: "pixels",
        endEnhancer: "px",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: true, required: true },
      },
      {
        id: "codeFormats",
        label: "Kodowanie",
        type: FIELDS.BarcodeFormatSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
      },
      {
        id: "codeFormat",
        label: "Kodowanie",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "pdf",
    label: "Parametry pliku PDF",
    fields: [
      {
        id: "stickerWidth",
        label: "Szerokość",
        type: FIELDS.NumberInput,
        dataType: "pixels",
        endEnhancer: "px",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: true },
      },
      {
        id: "stickerHeight",
        label: "Wysokość",
        type: FIELDS.NumberInput,
        dataType: "pixels",
        endEnhancer: "px",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: true },
      },
    ],
  },
];
