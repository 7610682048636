import { gql } from "@apollo/client";

export const BILL_ISSUANCE_REQUEST_CREATE = gql`
  mutation CreateBillIssuanceRequest(
    $billIssuanceRequestCreateInput: BillIssuanceRequestCreateInput!
    $files: [Upload!]
  ) {
    billIssuanceRequestCreate(
      billIssuanceRequestCreateInput: $billIssuanceRequestCreateInput
      billIssuanceRequestFiles: $files
    ) {
      id
    }
  }
`;

export const BILL_ISSUANCE_REQUEST_EDIT = gql`
  mutation(
    $billIssuanceRequestUpdateInput: BillIssuanceRequestUpdateInput!
    $files: [Upload!]
  ) {
    billIssuanceRequestUpdate(
      billIssuanceRequestUpdateInput: $billIssuanceRequestUpdateInput
      billIssuanceRequestFiles: $files
    ) {
      id
      type
    }
  }
`;

export const BILL_ISSUANCE_REQUEST_DELETE = gql`
  mutation($billIssuanceRequestDeleteInput: BillIssuanceRequestDeleteInput!) {
    billIssuanceRequestDelete(
      billIssuanceRequestDeleteInput: $billIssuanceRequestDeleteInput
    ) {
      id
    }
  }
`;

export const BILL_ISSUANCE_REQUEST_SHOW = gql`
  query($id: Int!) {
    billIssuanceRequest(id: $id) {
      id
      type
      workedHoursCount
      contractorAccountNumber
      remarks
      grossValue
      contractorName
      fundingSource
      internalNumber
      isReleasable
      createdAt
      updatedAt
      description
      caseId
      sequenceNumber
      isEditable
      isSequenceNumberEditable
      state
      responsible {
        id
        firstName
        lastName
      }
      isResponsibleSettable
      createdBy {
        id
        firstName
        lastName
      }
      case {
        id
        name
        number
        isConductedElectronically
      }
      currency {
        id
        code
      }
      documentType
      organizationalUnit {
        id
        name
        symbol
      }
      documentKind
      documentLinks {
        id
        linkerId
        linker {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        linkedId
        linked {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        type
        createdAt
      }
      documentRoot {
        ... on RequestForm {
          internalNumber
          legalAct
          id
          documentType
          type
          documentNumber
          documentKind
        }
        ... on BillIssuanceRequest {
          internalNumber
          id
          documentType
          type
          documentKind
        }
        ... on ContractPreparationRequest {
          internalNumber
          id
          documentType
          type
          documentKind
        }
        ... on ContractAgreement {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on FinancialAccountingDocument {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on Payment {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on Document {
          id
          internalNumber
          documentType
          type
          documentKind
        }
      }
      children {
        id
        documentNumber
        internalNumber
        documentKind
        documentType
        type
        documentDate
        createdAt
        postStampAt
      }
      currentStatus {
        documentFlowStepId
        documentFlowStep {
          id
          name
          isInitial
          isProcessedByDocumentFlowStarter
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
          }
          group {
            id
            name
          }
          users {
            firstName
            lastName
          }
          allowedDocumentFields
          isAllowedToEditAllFields
        }
        createdAt
      }
      statuses {
        id
        comment
        signatureFilePath
        signatureFileMimetype
        signatureDownloadUrl
        user {
          id
          firstName
          lastName
          position
        }
        userPosition
        userOrganizationalUnits
        documentFlowRecipients
        substituter {
          id
          firstName
          lastName
          position
        }
        documentFlowStep {
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
            symbol
          }
          users {
            id
            firstName
            lastName
          }
          positions {
            id
            name
          }
          id
          name
          isProcessedByDocumentFlowStarter
        }
        documentFlowAction {
          label
          style
          resultingDocumentState
          isEndingDocumentFlow
        }
        createdAt
      }
      documentFlowId
      isDocumentUsedInFlow
      documentFlow {
        id
        name
        steps {
          id
          name
        }
      }
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
      activityLogs {
        id
        activity
        description
        user {
          firstName
          lastName
          id
        }
        substituter {
          id
          firstName
          lastName
        }
        assignmentUsers
        attachmentData
        properties
        createdAt
        userOrganizationalUnits
      }
      isDispatchable
      isDispatchCancelable
      isShareable
      shippedAt
      currentAssignment {
        id
        primaryAssignableId
        isAssessmentRequested
        assignerComment
        createdAt
        type
        status
        assigner {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const BILL_ISSUANCE_REQUEST_FILES_SHOW = gql`
  query($id: Int!) {
    billIssuanceRequest(id: $id) {
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
    }
  }
`;
