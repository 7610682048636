import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  AlertOctagon,
  DeviceFloppy,
  Download,
  FileOff,
  InfoCircle,
  Note,
} from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import GusDownloadModal from "../../../../components/gus-download-modal";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import { ControlledRadioCheckbox } from "../../../../components/radio-checkbox";
import {
  ControlledAddressesSelect,
  ControlledCasesSelect,
  ControlledDictionaryValuesSelect,
  ControlledDocumentsSelect,
  ControlledDocumentTypesSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledOrganizationalUnitUsersSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import Tooltip from "../../../../components/tooltip";
import { HIDDEN_ADDRESS, LOCAL_STORAGE_VARIABLES } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { Address, DefaultAddress } from "../../../Addresses/addresses";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../../Addresses/addresses.gql";
import { AssignmentLocationState } from "../../../Folders/folders";
import { DocumentInputs, DOCUMENTS_FIELDS } from "../../documents.form";
import {
  CHECK_EXISTING_DOCUMENT_BY_NIP_AND_DOCUMENT_NUMBER,
  DOCUMENTS_CREATE,
} from "../../documents.gql";

export default function DocumentsCreateIncoming(): React.ReactElement {
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState<DefaultAddress>();
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const { user } = useAuth();
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(true);
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [
    isSaveDocumentConfirmDialogOpen,
    setIsSaveDocumentConfirmDialogOpen,
  ] = useState(false);
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, isLoading, setIsLoading } = useLoading();

  const { assignment } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    register,
    unregister,
    watch,
  } = useForm<DocumentInputs>({
    defaultValues: {
      documentNumber: "",
      documentType: undefined,
      documentDate: null,
      inflowDate: null,
      postStampAt: null,
      description: "",
      title: "",
      programName: "",
      caseName: undefined,
      senderWhenAddress: undefined,
      addressName: "",
      addressStreetName: "",
      addressBuildingNumber: "",
      addressApartmentNumber: "",
      addressMobileNumber: "",
      addressCity: "",
      addressPostalCode: "",
      sequenceNumber: "",
      addressPhoneNumber: "",
      addressEmail: "",
      addressCountryCode: undefined,
      addressIsSensitive: false,
      addressNip: "",
      handDelivered: false,
      organizationalUnit: undefined,
      recipientWhenUser: undefined,
      addressOverwrite: false,
      parentIds: undefined,
      files: undefined,
      isFullyDigitallyReproduced: "true",
    },
  });

  const watchAddress = watch("senderWhenAddress");
  const watchOrganizationalUnit = watch("organizationalUnit");
  const watchHandDelivered = watch("handDelivered");
  const watchRecipientWhenUser = watch("recipientWhenUser");
  const watchNip = watch("addressNip");
  const watchCountryCode = watch("addressCountryCode");
  const watchIsElectronical = watch("isElectronical");
  const watchDocumentNumber = watch("documentNumber");

  const isSensitiveAddressChoosed =
    watchAddress && watchAddress?.[0]?.typename === HIDDEN_ADDRESS;

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const [
    shouldCheckExistingDocuments,
    setShouldCheckExistingDocuments,
  ] = useState(false);

  const { refetch: existingDocumentsRefetch } = useQuery(
    CHECK_EXISTING_DOCUMENT_BY_NIP_AND_DOCUMENT_NUMBER,
    {
      variables: {
        addressNip: watchNip,
        documentNumber: watchDocumentNumber,
      },
      fetchPolicy: "network-only",
      skip: !shouldCheckExistingDocuments,
    }
  );

  const [createDocument, { error: mutationError }] = useMutation(
    DOCUMENTS_CREATE,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const time = new Date();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    description,
    title,
    programName,
    parentIds,
    documentType,
    documentDate,
    inflowDate,
    sequenceNumber,
    postStampAt,
    senderWhenAddress,
    caseName,
    organizationalUnit,
    documentNumber,
    handDelivered,
    files,
    addressName,
    addressNip,
    addressCity,
    addressStreetName,
    addressBuildingNumber,
    addressApartmentNumber,
    addressMobileNumber,
    addressPostalCode,
    addressCountryCode,
    addressEmail,
    addressPhoneNumber,
    addressOverwrite,
    addressIsSensitive,
    isFullyDigitallyReproduced,
    name,
    additionalCode,
    isElectronical,
    raksDiaryNumber,
  }: DocumentInputs): Promise<void> => {
    setIsLoading(true);

    setShouldCheckExistingDocuments(true);

    const refetchExistingDocumentChecking = await existingDocumentsRefetch();

    if (
      refetchExistingDocumentChecking.data
        ?.checkExistingDocumentByNIPAndDocumentNumber &&
      !isSaveDocumentConfirmDialogOpen
    ) {
      setIsLoading(false);
      setIsSaveDocumentConfirmDialogOpen(true);
      setShouldCheckExistingDocuments(false);
      return;
    }

    localStorage.setItem(
      LOCAL_STORAGE_VARIABLES.LastRegisteredIncomingDocument,
      documentType && documentType[0]?.id.toString()
    );

    const enteredAddress = {
      addressName,
      addressNip,
      addressStreetName,
      addressBuildingNumber,
      addressApartmentNumber,
      addressMobileNumber,
      addressCity,
      addressCountryCode,
      addressPostalCode,
      addressPhoneNumber,
      addressEmail,
      addressIsSensitive,
    };

    const shouldSendAddress =
      JSON.stringify(enteredAddress) !== JSON.stringify(defaultAddress);

    try {
      const response = await createDocument({
        variables: {
          documentCreateInput: {
            parentIds: parentIds && parentIds.map(({ id }) => id),
            documentNumber,
            documentKind: "Incoming",
            documentDate,
            inflowDate,
            description,
            title,
            name,
            userId: user?.id,
            sequenceNumber,
            additionalCode,
            programName,
            documentType: documentType && documentType[0]?.id,
            postStampAt,
            handDelivered,
            senderId: senderWhenAddress && senderWhenAddress[0]?.id,
            senderType: "Address",
            caseId: caseName ? caseName[0]?.id : null,
            raksDiaryNumber,
            organizationalUnitId:
              organizationalUnit && organizationalUnit[0]?.id,
            ...(isElectronical === "false" && {
              isFullyDigitallyReproduced: isFullyDigitallyReproduced === "true",
            }),
            isElectronical: isElectronical === "true",
          },
          files,
          ...(shouldSendAddress && {
            addressInput: {
              name: addressName,
              nip: addressNip && addressNip?.replaceAll("-", "").toString(),
              city: addressCity,
              streetName: addressStreetName,
              buildingNumber: addressBuildingNumber,
              apartmentNumber: addressApartmentNumber,
              mobileNumber: addressMobileNumber,
              postalCode: addressPostalCode,
              countryCode: addressCountryCode && addressCountryCode[0]?.id,
              email: addressEmail ? addressEmail : null,
              phoneNumber: addressPhoneNumber ? addressPhoneNumber : null,
              overwrite: !!addressOverwrite,
              isSensitive: !!addressIsSensitive,
            },
          }),
        },
      });

      enqueueSnackbar({
        message: "Zarejestrowano pomyślnie",
        variant: "success",
      });
      history.push(
        `/documents/${response?.data?.documentCreate?.documentKind?.toLowerCase()}/Document/${
          response?.data?.documentCreate?.id
        }`
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id: watchAddress && watchAddress[0] && watchAddress[0].id,
      });

      const choosedAddress = response?.data?.addressSuggestion;
      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.isSensitive);
      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      setIsAnyAddressRevealed(true);
      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errors) scrollOnError(errors, DOCUMENTS_FIELDS, "create");
  }, [errorTimeStamp]);

  useEffect(() => {
    setAddressFromGus(undefined);
    async function getAddressData() {
      setValue("addressName", "");
      setValue("addressNip", "");
      setValue("addressStreetName", "");
      setValue("addressBuildingNumber", "");
      setValue("addressApartmentNumber", "");
      setValue("addressMobileNumber", "");
      setValue("addressCity", "");
      setValue("addressPostalCode", "");
      setValue("addressPhoneNumber", "");
      setValue("addressEmail", "");
      setValue("addressIsSensitive", false);
      if (watchAddress && watchAddress[0] && watchAddress[0].id) {
        const response = await fetchAddress({
          filter: {
            id: {
              eq: watchAddress[0].id as number,
            },
          },
        });
        const choosedAddress = response?.data?.addressSuggestions?.nodes[0];

        if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
        if (watchAddress[0].typename === "HiddenAddress") {
          setValue("addressIsSensitive", true);
          setValue("addressCountryCode", []);
        }

        if (watchAddress[0].typename === "Address") {
          if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
          if (choosedAddress?.streetName)
            setValue("addressStreetName", choosedAddress?.streetName);
          if (choosedAddress?.buildingNumber)
            setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
          if (choosedAddress?.apartmentNumber)
            setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
          if (choosedAddress?.mobileNumber)
            setValue("addressMobileNumber", choosedAddress?.mobileNumber);
          if (choosedAddress?.city)
            setValue("addressCity", choosedAddress?.city);
          if (choosedAddress?.postalCode)
            setValue("addressPostalCode", choosedAddress?.postalCode);
          if (choosedAddress?.countryCode)
            setValue("addressCountryCode", [
              {
                id: choosedAddress?.countryCode,
              },
            ]);
          if (choosedAddress?.phoneNumber)
            setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
          if (choosedAddress?.email)
            setValue("addressEmail", choosedAddress?.email);
        }

        setDefaultAddress({
          addressName: choosedAddress?.name || "",
          addressNip: choosedAddress?.nip || "",
          addressStreetName: choosedAddress?.streetName || "",
          addressBuildingNumber: choosedAddress?.buildingNumber || "",
          addressApartmentNumber: choosedAddress?.apartmentNumber || "",
          addressMobileNumber: choosedAddress?.mobileNumber || "",
          addressCity: choosedAddress?.city || "",
          addressCountryCode: choosedAddress?.countryCode
            ? [
                {
                  id: choosedAddress?.countryCode,
                },
              ]
            : [],
          addressPostalCode: choosedAddress?.postalCode || "",
          addressPhoneNumber: choosedAddress?.phoneNumber || "",
          addressEmail: choosedAddress?.email || "",
          addressIsSensitive: choosedAddress?.__typename === HIDDEN_ADDRESS,
        });
        unregister("addressName");
        unregister("addressStreetName");
        unregister("addressBuildingNumber");
        unregister("addressApartmentNumber");
        unregister("addressMobileNumber");
        unregister("addressPostalCode");
        unregister("addressCity");
        unregister("addressCountryCode");
      }
    }
    !addressFromGus && getAddressData();

    watchAddress?.[0]?.typename === "Address"
      ? setIsAnyAddressRevealed(true)
      : setIsAnyAddressRevealed(false);
  }, [watchAddress]);

  useEffect(() => {
    if (watchIsElectronical === "true") {
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");

      register("addressEmail");
    } else if (watchIsElectronical === "false") {
      unregister("addressEmail");

      register("addressStreetName");
      register("addressBuildingNumber");
      register("addressApartmentNumber");
      register("addressMobileNumber");
      register("addressPostalCode");
      register("addressCity");
      register("addressCountryCode");
    }
  }, [watchIsElectronical]);

  useEffect(() => {
    setValue("inflowDate", new Date());

    const lastRegisteredDocumentType = localStorage.getItem(
      LOCAL_STORAGE_VARIABLES.LastRegisteredIncomingDocument
    );
    lastRegisteredDocumentType &&
      setValue("documentType", [{ id: lastRegisteredDocumentType }]);
  }, []);

  useEffect(() => {
    if (watchHandDelivered) setValue("postStampAt", null);
  }, [watchHandDelivered]);

  useEffect(() => {
    if (addressFromGus) setValue("senderWhenAddress", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  if (!checkPermission(PERMISSIONS.document.create)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Rejestracja dokumentu przychodzącego"
        labels={["Dokumenty", "Rejestracja"]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty
                ? setIsCancelConfirmDialogOpen(true)
                : history.push("/documents"),
          },
          {
            label: "Zarejestruj",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "createIncomingDocument",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <FormControl label="Data rejestracji w systemie" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{time.toLocaleDateString()}</FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="createIncomingDocument" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {DOCUMENTS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create?.visible).length > 0
            )
              .filter(
                (g) =>
                  g.fields.filter((f) => f?.typeName?.includes("Incoming"))
                    .length > 0
              )
              .map((group) => [
                group.label && (
                  <Cell key={group.id + `-group`} span={12}>
                    <>
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom="scale200"
                        marginTop="scale600"
                        height="20px"
                      >
                        <Block
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <LabelMedium>{group.label}</LabelMedium>
                          {watchAddress?.[0]?.typename === "HiddenAddress" &&
                            !isAnyAddressRevealed &&
                            group.id === "sender" && (
                              <Button
                                size={SIZE.mini}
                                kind={KIND.secondary}
                                $style={{ marginLeft: "10px" }}
                                onClick={() =>
                                  setIsAddressRevealConfirmDialogOpen(true)
                                }
                                isLoading={revealAddressLoading}
                                type="button"
                              >
                                Pokaż dane
                              </Button>
                            )}
                        </Block>
                        {watchAddress?.[0]?.typename === "HiddenAddress" &&
                          !isAnyAddressRevealed &&
                          group.id === "sender" && (
                            <FormattedValue
                              dataType="pre"
                              $style={{ fontSize: "12px" }}
                            >
                              Dane osobowe - podgląd zablokowany
                            </FormattedValue>
                          )}
                      </Block>
                      <hr
                        className={css({
                          borderWidth: "0px",
                          height: "1px",
                          backgroundColor: "#eee",
                        })}
                      />
                      {group.id === "recipient" ? (
                        <div
                          className={css({
                            backgroundColor: theme.colors.warning100,
                            color: theme.colors.warning700,
                            padding: "12px",
                            borderRadius: "6px",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "6px",
                            width: "fit-content",
                          })}
                        >
                          <InfoCircle
                            color={theme.colors.warning700}
                            size={16}
                          />

                          <Block marginLeft={"8px"}>
                            W przypadku obiegu zdefiniowanego należy odznaczyć
                            jednostkę organizacyjną, do której ma trafić
                            dokument.
                          </Block>
                        </div>
                      ) : null}
                    </>
                  </Cell>
                ),
                group.fields
                  .filter((f) => f.create?.visible)
                  .filter((f) => f.typeName?.includes("Incoming"))
                  .filter(
                    (f) =>
                      f.id !== "isFullyDigitallyReproduced" ||
                      (f.id === "isFullyDigitallyReproduced" &&
                        watchIsElectronical === "false")
                  )
                  .map((item, index) => {
                    return (
                      <Cell
                        span={item.span || 6}
                        skip={item.skip || 0}
                        key={group.id + `-field` + index}
                      >
                        <FormControl
                          label={
                            item.type === FIELDS.Checkbox ? "" : item.label
                          }
                          caption={item.caption}
                          required={
                            (item.info === "notElectronicalRequired" &&
                              watchIsElectronical === "false") ||
                            (item.info === "electronicalRequired" &&
                              watchIsElectronical === "true") ||
                            item.create.required
                          }
                          error={
                            ((errors as any)[item.id] &&
                              (errors as any)[item.id].message) ||
                            (mutationError &&
                              mutationError.graphQLErrors[0]?.extensions
                                ?.code === "INPUT_VALIDATION_ERROR" &&
                              mutationError.graphQLErrors[0]?.extensions?.validationErrors
                                ?.find((vE: InputValidationError) => {
                                  const itemId = item.id.includes("address")
                                    ? item.id.slice(7).charAt(0).toLowerCase() +
                                      item.id.slice(8)
                                    : item.id;

                                  return vE?.property === itemId;
                                })
                                ?.errors.map((message: string) => (
                                  <div
                                    key="error"
                                    className={css({
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    })}
                                  >
                                    {message}
                                    <AlertOctagon color="#999" size={12} />
                                  </div>
                                ))[0])
                          }
                          disabled={isLoading}
                        >
                          {item.type === FIELDS.AddressesSelect ? (
                            <ControlledAddressesSelect
                              control={control}
                              id={item.id}
                              name={item.id}
                              placeholder={item.placeholder}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.UsersSelect ? (
                            <ControlledUsersSelect
                              control={control}
                              id={item.id}
                              name={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.CasesSelect ? (
                            <ControlledCasesSelect
                              control={control}
                              id={item.id}
                              name={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                            <ControlledOrganizationalUnitsSelect
                              control={control}
                              id={item.id}
                              name={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.DocumentsSelect ? (
                            <ControlledDocumentsSelect
                              control={control}
                              id={item.id}
                              name={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type ===
                            FIELDS.OrganizationalUnitUsersSelect ? (
                            watchOrganizationalUnit &&
                            watchOrganizationalUnit.length > 0 ? (
                              <ControlledOrganizationalUnitUsersSelect
                                objectId={watchOrganizationalUnit[0].id}
                                control={control}
                                id={item.id}
                                name={item.id}
                                placeholder="Wybierz"
                                {...(item.create.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              />
                            ) : (
                              <ControlledUsersSelect
                                control={control}
                                name={item.id}
                                id={item.id}
                                placeholder="Wybierz"
                                {...(item.create.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              />
                            )
                          ) : item.type ===
                            FIELDS.UsersOrganizationalUnitsSelect ? (
                            watchRecipientWhenUser &&
                            watchRecipientWhenUser.length > 0 ? (
                              <ControlledUsersOrganizationalUnitsSelect
                                control={control}
                                name={item.id}
                                id={item.id}
                                objectId={watchRecipientWhenUser[0].id}
                                placeholder="Wybierz"
                                {...(item.create.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              />
                            ) : (
                              <ControlledOrganizationalUnitsSelect
                                control={control}
                                name={item.id}
                                id={item.id}
                                placeholder="Wybierz"
                                {...(item.create.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              />
                            )
                          ) : item.type === FIELDS.DictionaryValuesSelect ? (
                            <ControlledDictionaryValuesSelect
                              dictionarySystemName={
                                item.id === "addressCountryCode"
                                  ? "countryCodes.alpha2.pl"
                                  : item.id === "documentType"
                                  ? "documentTypes.incoming"
                                  : undefined
                              }
                              control={control}
                              disabled={
                                item.id === "addressCountryCode" &&
                                !isAnyAddressRevealed &&
                                !!watchAddress?.length
                              }
                              name={item.id}
                              id={item.id}
                              placeholder={
                                watchAddress?.[0]?.typename ===
                                  "HiddenAddress" &&
                                item.additionalData === "sensitive"
                                  ? "**********"
                                  : "Wybierz"
                              }
                              {...((item.create.required ||
                                (item.info === "electronicalRequired" &&
                                  watchIsElectronical === "true" &&
                                  !isSensitiveAddressChoosed) ||
                                (item.info === "notElectronicalRequired" &&
                                  watchIsElectronical === "false" &&
                                  !isSensitiveAddressChoosed)) && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.DocumentTypesSelect ? (
                            <ControlledDocumentTypesSelect
                              documentKind={"Incoming"}
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder={"Wybierz"}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.RadioCheckbox ? (
                            <ControlledRadioCheckbox
                              control={control}
                              name={item.id}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                              {...(item.id === "isElectronical" && {
                                trueText: "Elektroniczna",
                                falseText: "Papierowa",
                              })}
                            />
                          ) : item.type === FIELDS.DatePicker ? (
                            <div
                              className={css({
                                position: "relative",
                              })}
                            >
                              <ControlledDatePicker
                                name={item.id}
                                control={control}
                                {...(item.create.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                                disabled={
                                  (item.id === "postStampAt" &&
                                    watchHandDelivered) ||
                                  isLoading
                                }
                                {...(item.id === "postStampAt" && {
                                  $style: { paddingRight: "35px" },
                                })}
                              />
                            </div>
                          ) : item.type === FIELDS.FilesPicker ? (
                            <ControlledFilesPicker
                              control={control}
                              name={item.id}
                              maxSize={134217728}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.Checkbox ? (
                            <div className={css({ marginTop: "40px" })}>
                              {item.id === "addressOverwrite" ? (
                                <Tooltip
                                  placement="bottomLeft"
                                  content={
                                    <div>
                                      Zaznaczenie tej opcji spowoduje
                                      aktualizację danych <br /> wybranego
                                      adresata w bazie, ale nie wpłynie to na{" "}
                                      <br /> wcześniejsze dokumenty powiązane z
                                      tym adresatem.
                                    </div>
                                  }
                                >
                                  <span>
                                    <ControlledCheckbox
                                      control={control}
                                      name={item.id}
                                      disabled={
                                        isLoading ||
                                        (!isAnyAddressRevealed &&
                                          !!watchAddress?.length &&
                                          item.id.includes("address") &&
                                          !item.id.includes("Name"))
                                      }
                                      {...(item.create.required && {
                                        rules: {
                                          required:
                                            formValidation.messages.required,
                                        },
                                      })}
                                    >
                                      {item.label}
                                    </ControlledCheckbox>
                                  </span>
                                </Tooltip>
                              ) : (
                                <ControlledCheckbox
                                  control={control}
                                  name={item.id}
                                  disabled={
                                    isLoading ||
                                    (!isAnyAddressRevealed &&
                                      !!watchAddress?.length &&
                                      item.id.includes("address") &&
                                      !item.id.includes("Name"))
                                  }
                                  {...(item.create.required && {
                                    rules: {
                                      required:
                                        formValidation.messages.required,
                                    },
                                  })}
                                >
                                  {item.label}
                                </ControlledCheckbox>
                              )}
                            </div>
                          ) : item.type === FIELDS.TextArea ? (
                            <ControlledTextArea
                              control={control}
                              name={item.id}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : (
                            <ControlledInput
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder={
                                !isAnyAddressRevealed &&
                                !!watchAddress?.length &&
                                item.additionalData === "sensitive"
                                  ? "**********"
                                  : item.placeholder
                              }
                              rules={{
                                ...(((item.info === "notElectronicalRequired" &&
                                  watchIsElectronical === "false" &&
                                  !isSensitiveAddressChoosed) ||
                                  (item.info === "electronicalRequired" &&
                                    watchIsElectronical === "true" &&
                                    !isSensitiveAddressChoosed) ||
                                  item.create.required) && {
                                  required: formValidation.messages.required,
                                }),
                                ...(item.id === "addressEmail" && {
                                  pattern: {
                                    value: formValidation.patterns.email,
                                    message:
                                      formValidation.messages.incorrectEmail,
                                  },
                                }),
                              }}
                              masked={
                                item.id === "addressNip"
                                  ? watchCountryCode?.[0]?.id === "PL"
                                    ? item.mask !== undefined
                                    : false
                                  : item.mask !== undefined
                              }
                              mask={item.mask}
                              maskChar="_"
                              disabled={
                                (!isAnyAddressRevealed &&
                                  !!watchAddress?.length &&
                                  item.id.includes("address")) ||
                                (item.id === "sequenceNumber" &&
                                  !checkPermission(
                                    PERMISSIONS.document.updateSequenceNumber
                                  ))
                              }
                              endEnhancer={
                                item.id === "addressNip" ? (
                                  <Tooltip content="Pobierz z GUS">
                                    <span
                                      onClick={() => setIsGusDownloadOpen(true)}
                                      className={css({ cursor: "pointer" })}
                                    >
                                      <Download
                                        size={16}
                                        className={css({
                                          verticalAlign: "middle",
                                          marginLeft: "6px",
                                          display: "inline",
                                        })}
                                      />
                                    </span>
                                  </Tooltip>
                                ) : (
                                  item.endEnhancer
                                )
                              }
                            />
                          )}
                        </FormControl>
                      </Cell>
                    );
                  }),
              ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie rejestracji dokumentu przychodzącego"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.push("/documents")}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
            <ConfirmDialog
              isOpen={isSaveDocumentConfirmDialogOpen}
              type={ConfirmDialogType.SaveDocumentWithDuplicatedData}
              close={() => setIsSaveDocumentConfirmDialogOpen(false)}
              confirm={() => {
                handleSubmit(onSubmit)();
                setIsSaveDocumentConfirmDialogOpen(false);
              }}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
