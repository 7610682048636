import { useQuery } from "@apollo/client";
import React, { createContext, useContext, useState } from "react";

import { CodeFormat } from "../containers/Barcodes/barcodes";
import { BARCODES_SHOW } from "../containers/Barcodes/barcodes.gql";
import { useAuth } from "./auth-context";

type BarcodeContextProps = {
  codeFormat?: CodeFormat;
  width?: number;
  height?: number;
};

export const BarcodesContext = createContext<BarcodeContextProps>(
  {} as BarcodeContextProps
);

export function BarcodesProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [codeFormat, setCodeFormat] = useState<CodeFormat>();
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  const { isAuthenticated } = useAuth();

  useQuery(BARCODES_SHOW, {
    variables: {
      id: 1,
    },
    fetchPolicy: "cache-and-network",
    skip: !isAuthenticated,
    onCompleted({ barcode }) {
      setCodeFormat(barcode.codeFormat);
      setWidth(barcode.width);
      setHeight(barcode.height);
    },
  });

  return (
    <BarcodesContext.Provider
      value={{
        codeFormat,
        width,
        height,
      }}
    >
      {children}
    </BarcodesContext.Provider>
  );
}

export const useBarcodes = (): BarcodeContextProps =>
  useContext(BarcodesContext);
