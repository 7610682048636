export enum FIELDS {
  Input = "input",
  NumberInput = "number-input",
  Select = "select",
  OrganizationalUnitUsersSelect = "organizational-unit-users-select",
  OrganizationalUnitsSelect = "organizational-units-select",
  UsersOrganizationalUnitsSelect = "users-organizational-units-select",
  JrwaClassificationsSelect = "jrwa-classifications-select",
  AddressesSelect = "adresses-select",
  RolesSelect = "roles-select",
  RolesMultiSelect = "roles-multi-select",
  UsersSelect = "users-select",
  MyOrganizationalUnitsUsersSelect = "my-organizational-units-users-select",
  CasesSelect = "cases-select",
  DatePicker = "date-picker",
  PermissionsTable = "permissions-table",
  DictionaryValuesSelect = "dictionary-values-select",
  Checkbox = "checkbox",
  RadioCheckbox = "radio-checkbox",
  ContractStatusSelect = "contract-status-select",
  ContractTypeSelect = "contract-type-select",
  OrderTypeSelect = "order-type-select",
  CurrencySelect = "currency-select",
  FilesPicker = "files-picker",
  DocumentsSelect = "documents-select",
  FilesList = "files-list",
  StepsSelect = "steps-select",
  RadioButton = "radio-button",
  DocumentTypesSelect = "document-types-select",
  RequestFormTable = "request-form-table",
  ContractAgreementSelect = "contract-agreement-select",
  TextArea = "text-area",
  ShipmentApiSelect = "shipment-api-select",
  RadioButton = "radio-button",
  ParcelSizesSelect = "parcel-sizes-select",
  SendingListsSelect = "sending-lists-select",
  ShipmentRatesSelect = "shipment-rates-select",
  PositionsSelect = "positions-select",
  ENadawcaShipmentParcelsSelect = "enadawca-shipment-select",
  ENadawcaGabarytSelect = "enadawca-gabaryt-select",
  ENadawcaFormatSelect = "enadawca-format-select",
  ENadawcaKategoriaSelect = "enadawca-kategoria-select",
  ENadawcaAddressTypeSelect = "enadawca-address-type-select",
  DocumentParcelSelect = "document-parcel-select",
  GroupPicker = "group-picker",
  GroupSelect = "group-select",
  InvoiceTable = "invoice-table",
  RequestFormItemsSelect = "request-form-items-select",
  PaymentTypesSelect = "payment-types-select",
  DocumentKindsSelect = "document-kinds-select",
  TransferTable = "transfer-table",
  TransferTemplatesSelect = "transfer-templates-select",
  FundingSourceSelect = "funding-source-select",
  InternalAccountsSelect = "internal-accounts-select",
  DocumentStatesSelect = "document-states-select",
  ButtonStylesSelect = "button-styles-select",
  InvoiceTypeSelect = "invoice-type-select",
  DocumentTypesSelect = "document-types-select",
  CurrencyExchangeRatesSelect = "currency-exchange-rates-select",
  RequestFormsSelect = "request-forms-select",
  BankAccountTypesSelect = "bank-account-types-select",
  BankAccountsSelect = "bank-accounts-select",
  AddressForm = "address-form",
  CaseConductSwitch = "case-conduct-switch",
  CaseShareSwitch = "case-share-switch",
  DeklaracjaCelnaRodzajSelect = "deklaracja-celna-rodzaj-select",
  ZawartoscPrzesylkiSelect = "zawartosc-przesylki-select",
  DokumentyTowarzyszaceRodzajSelect = "dokumenty-towarzyszace-rodzaj-select",
  SubstitutionStatus = "substitution-status",
  UsersLiveSearchTableSelect = "users-live-search-table-select",
  DocumentsFieldsTableSelect = "documents-fields-table-select",
  BarcodeFormatSelect = "barcode-format-select",
}

type BasicField = {
  id: string;
  label: string;
  caption?: string;
  placeholder?: string;
  info?: string;
  span?: number;
  skip?: number;
  dataType?: string;
  additionalData?: any;
  typeName?: string | string[];
  documentType?: string | string[];
  type: FIELDS[keyof FIELDS];
  mask?: string;
  maskChar?: string;
  endEnhancer?: string;
  startEnhancer?: string;
  readonly?: boolean;
  disabled?: boolean;
  minDate?: Date;
};

export type Field = {
  show: { accessor?: string | string[]; visible: boolean };
  create: { visible: boolean; required?: boolean };
  edit: { visible: boolean; required?: boolean };
} & BasicField;

type BasicFieldsGroup = {
  id: string;
  label: string;
};

export type FlowStepField = {
  showStep: { accessor?: string | string[]; visible: boolean };
  editStep: { visible: boolean; required?: boolean };
  createStep: { visible: boolean; required?: boolean };
} & BasicField;

export type FlowField = {
  showFlow: { accessor?: string | string[]; visible: boolean };
  editFlow: { visible: boolean; required?: boolean };
  createFlow: { visible: boolean; required?: boolean };
} & BasicField;

export type FieldsGroup = {
  fields: Field[];
  accessor?: string;
} & BasicFieldsGroup;

export type FlowFieldsGroup = {
  fields: FlowField[];
} & BasicFieldsGroup;

export type FlowStepsFieldsGroup = {
  fields: FlowStepField[];
} & BasicFieldsGroup;
