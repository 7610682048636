import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Pencil } from "tabler-icons-react";

import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { BARCODES_FIELDS } from "../barcodes.form";
import { BARCODES_SHOW } from "../barcodes.gql";

export default function BarcodesShow(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(BARCODES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.barcode) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.barcode && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.barcode.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={"Kod kreskowy"}
        labels={["Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.barcode.update),
            onClick: () => history.push(`/barcodes/${id}/edit`),
          },
        ]}
      />
      <Content>
        <Grid>
          {BARCODES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl
                    label={item.label}
                    disabled={true}
                    overrides={{
                      ControlContainer: {
                        props: {
                          "data-test-id": item.id,
                        },
                      },
                    }}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.barcode[item.id]}
                      >
                        {item.show.accessor
                          ? data?.barcode[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.barcode[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dane systemowe
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={3}>
            <FormControl label="Ostatnia aktualizacja" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue
                  dataType="datetime"
                  data={data?.barcode?.updatedAt}
                >
                  {data?.barcode?.updatedAt}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={3}>
            <FormControl label="Zaktualizowano przez" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue
                  dataType="model:users"
                  data={data?.barcode?.updatedBy?.id}
                >
                  {`${data?.barcode?.updatedBy?.firstName} ${data?.barcode?.updatedBy?.lastName}`}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
