import { gql } from "@apollo/client";

export const FINANCIAL_ACCOUNTING_DOCUMENT_FILES_SHOW = gql`
  query($id: Int!) {
    financialAccountingDocument(id: $id) {
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
    }
  }
`;

export const FINANCIAL_ACCOUNTING_DOCUMENT_SHOW = gql`
  query($id: Int!) {
    financialAccountingDocument(id: $id) {
      id
      isReleasable
      sequenceNumber
      documentLinks {
        id
        linkerId
        linker {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        linkedId
        linked {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        type
        createdAt
      }
      documentRoot {
        ... on RequestForm {
          internalNumber
          legalAct
          id
          documentType
          type
          documentNumber
          documentKind
        }
        ... on BillIssuanceRequest {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on ContractPreparationRequest {
          internalNumber
          id
        }
        ... on ContractAgreement {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on FinancialAccountingDocument {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Payment {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Document {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
      }
      children {
        id
        documentNumber
        internalNumber
        documentKind
        documentType
        type
        documentDate
        createdAt
        postStampAt
      }
      responsible {
        id
        firstName
        lastName
      }
      isResponsibleSettable
      createdBy {
        id
        firstName
        lastName
      }
      documentNumber
      name
      description
      title
      raksDiaryNumber
      programName
      internalNumber
      documentKind
      type
      documentDate
      isEditable
      isSequenceNumberEditable
      inflowDate
      handDelivered
      documentType
      createdAt
      postStampAt
      handDelivered
      paymentAt
      netValue
      grossValue
      paymentType
      currency {
        id
        name
        code
      }
      currencyExchangeRate {
        id
        value
      }
      bankAccount {
        id
        payeeName
        currency
        accountNumber
        bankName
        accountType
        bankCode
      }
      amountInCurrency
      invoiceType
      rejestrId
      state
      sender {
        __typename
        ... on Address {
          id
          address
          streetName
          buildingNumber
          apartmentNumber
          mobileNumber
          name
          city
          postalCode
          nip
          phoneNumber
          name
          countryCode
          email
          isSensitive
          bankAccounts {
            id
            payeeName
            currency
            accountNumber
            bankName
            accountType
            bankCode
            addressId
          }
        }
        ... on HiddenAddress {
          id
          name
          userBankAccounts: bankAccounts {
            id
            payeeName
            currency
            accountNumber
            bankName
            accountType
            bankCode
            addressId
          }
        }
      }
      recipient {
        __typename
        ... on User {
          id
          firstName
          lastName
          organizationalUnitToUsers {
            organizationalUnit {
              id
              name
            }
          }
        }
        ... on Address {
          id
          address
          city
          postalCode
          nip
          phoneNumber
          name
          countryCode
          email
          isSensitive
        }
        ... on HiddenAddress {
          id
          name
        }
      }
      case {
        id
        name
        number
        isConductedElectronically
      }
      caseId
      organizationalUnit {
        id
        symbol
        name
      }
      createdAt
      updatedAt
      currentStatus {
        documentFlowStepId
        documentFlowStep {
          id
          name
          isInitial
          isProcessedByDocumentFlowStarter
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
          }
          group {
            id
            name
          }
          users {
            firstName
            lastName
          }
          allowedDocumentFields
          isAllowedToEditAllFields
        }
        createdAt
      }
      statuses {
        id
        comment
        signatureFilePath
        signatureFileMimetype
        signatureDownloadUrl
        user {
          id
          firstName
          lastName
          position
        }
        userPosition
        userOrganizationalUnits
        documentFlowRecipients
        substituter {
          id
          firstName
          lastName
          position
        }
        documentFlowStep {
          isPaymentCreationEnabled
          isInitial
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
            symbol
          }
          users {
            id
            firstName
            lastName
          }
          positions {
            id
            name
          }
          id
          name
          isProcessedByDocumentFlowStarter
        }
        documentFlowAction {
          label
          style
          resultingDocumentState
          isEndingDocumentFlow
        }
        createdAt
      }
      payments {
        id
      }
      documentFlowId
      isDocumentUsedInFlow
      documentFlow {
        id
        name
        steps {
          id
          name
        }
      }
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
      bankAccountId
      financialAccountingDocumentItems {
        id
        tableId
        description
        name
        totalAmount
        totalAmountWithTax
        requestFormItem {
          requestForm {
            id
            internalNumber
            documentNumber
            name
            title
          }
          id
          requestedExpenditure
          realExpenditure
          orderValue
          budgetSymbol
          categoryPath
          categoryName
          budgetName
          year
        }
      }
      activityLogs {
        id
        activity
        description
        user {
          firstName
          lastName
          id
        }
        substituter {
          id
          firstName
          lastName
        }
        assignmentUsers
        attachmentData
        properties
        createdAt
        userOrganizationalUnits
      }
      isDispatchable
      isDispatchCancelable
      isShareable
      shippedAt
      currentAssignment {
        id
        primaryAssignableId
        isAssessmentRequested
        assignerComment
        createdAt
        type
        status
        assigner {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const FINANCIAL_ACCOUNTING_DOCUMENT_UPDATE = gql`
  mutation(
    $financialAccountingDocumentUpdateInput: FinancialAccountingDocumentUpdateInput!
    $financialAccountingDocumentItemUpdateInputs: [FinancialAccountingDocumentItemUpdateInput!]!
    $bankAccountCreateInput: BankAccountCreateInput
    $addressInput: AddressInput
    $files: [Upload!]
  ) {
    financialAccountingDocumentUpdate(
      financialAccountingDocumentUpdateInput: $financialAccountingDocumentUpdateInput
      financialAccountingDocumentItemUpdateInputs: $financialAccountingDocumentItemUpdateInputs
      bankAccountCreateInput: $bankAccountCreateInput
      addressInput: $addressInput
      documentFiles: $files
    ) {
      id
    }
  }
`;

export const FINANCIAL_ACCOUTING_DOCUMENT_CREATE_FROM_DOCUMENT = gql`
  mutation(
    $financialAccountingDocumentCreateInput: FinancialAccountingDocumentCreateInput!
    $financialAccountingDocumentItemInputs: [FinancialAccountingDocumentItemInput!]!
    $bankAccountCreateInput: BankAccountCreateInput
    $files: [Upload!]
    $addressInput: AddressInput
    $documentId: Float!
  ) {
    financialAccountingDocumentCreateFromDocument(
      financialAccountingDocumentCreateInput: $financialAccountingDocumentCreateInput
      financialAccountingDocumentItemInputs: $financialAccountingDocumentItemInputs
      bankAccountCreateInput: $bankAccountCreateInput
      documentFiles: $files
      addressInput: $addressInput
      documentId: $documentId
    ) {
      id
    }
  }
`;
