import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Cell as TableCell, Row } from "react-table";
import { Eye, TableExport } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { DOCUMENT_TYPENAMES } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { BasicFilter, FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { getDocumentTypename } from "../../../utils/documents/document-typename";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { ActivityLog } from "../activity-logs";
import { ACTIVITY_LOGS_FILTERS } from "../activity-logs.filters";
import { ACTIVITY_LOGS_FIELDS } from "../activity-logs.form";
import {
  ACTIVITY_LOGS_EXPORT_TO_XLSX,
  ACTIVITY_LOGS_INDEX,
} from "../activity-logs.gql";

enum FieldName {
  Id = "id",
  Activity = "activity",
  CreatedAt = "createdAt",
}

export default function ActivityLogsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const { enqueueSnackbar } = useSnackbar();
  const [css] = useStyletron();
  const {
    isFetching,
    isPartialFetching,
    setIsPartialFetching,
    setIsFetching,
    setIsLoading,
  } = useLoading();
  const history = useHistory();

  const [filters, setFilters] = useState<FiltersState>();
  const [, theme] = useStyletron();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const [activityLogsExportToXlsx] = useMutation(ACTIVITY_LOGS_EXPORT_TO_XLSX, {
    variables: {
      ...(filters &&
        filters.length && { filter: translateFiltersState(filters) }),
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
    },
  });

  const exportActivityLogsToXlsx = async () => {
    setIsLoading(true);

    try {
      const response = await activityLogsExportToXlsx();

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "info",
      });

      window.open(
        `${process.env.REACT_APP_GRAPHQL_API_URL?.replace("/graphql", "")}${
          response?.data?.activityLogsExportToXlsx?.downloadUrl
        }`,
        "_self"
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch, loading, data, error } = useQuery(ACTIVITY_LOGS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (data?.activityLogs) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.activityLogs) setIsFetching(false);
    if (data?.activityLogs?.totalCount >= 0)
      setTotalCount(data?.activityLogs?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        id: "id",
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Id)}
            sortDirection={sortBy === FieldName.Id ? sortDirection : null}
          >
            Id
          </SortingTableHeader>
        ),
        accessor: "id",
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/activity-logs/${row.original.id}`);
            }}
            href={`/activity-logs/${row.original.id}`}
          >
            {row.original.id}
          </StyledLink>
        ),
      },
      {
        Header: "Użytkownik",
        id: "activityLogUser",
        Cell: ({ row }: { row: Row<ActivityLog> }) =>
          row.original.user?.id === undefined ? (
            row.original.properties?.login ? (
              <FormattedValue dataType="login">
                {row.original.properties?.login}
              </FormattedValue>
            ) : (
              <FormattedValue dataType="system"></FormattedValue>
            )
          ) : row.original.substituter ? (
            <div
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "5px",
              })}
            >
              <FormattedValue
                dataType={"model:users"}
                data={row.original.substituter?.id}
              >
                {`${row.original.substituter?.firstName} ${row.original.substituter?.lastName}`}
              </FormattedValue>

              <span className={css({ fontWeight: 400 })}>za</span>
              <FormattedValue
                dataType={"model:users"}
                data={row.original.user?.id}
              >
                {`${row.original.user?.firstName} ${row.original.user?.lastName}`}
              </FormattedValue>
              <span className={css({ fontWeight: 400 })}>(zastępstwo)</span>
            </div>
          ) : (
            <FormattedValue
              dataType={"model:users"}
              data={row.original.user?.id}
            >
              {`${row.original.user?.firstName} ${row.original.user?.lastName}`}
            </FormattedValue>
          ),
      },
      {
        Header: "Jednostka organizacyjna",
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <FormattedValue dataType="json:organizational-units" inline>
            {row.original.userOrganizationalUnits}
          </FormattedValue>
        ),
      },
      {
        Header: "Akcja",
        accessor: "description",
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <FormattedValue>
            {row.original.subject?.__typename
              ? `${row.original.description} (${row.original.subject?.__typename})`
              : row.original.description}
          </FormattedValue>
        ),
      },
      {
        Header: "Obiekt",
        id: "subject.id",
        Cell: ({ row }: { row: Row<any> }) => {
          const item = ACTIVITY_LOGS_FIELDS.find(
            (group) => group.id === "details"
          )?.fields.find(
            (field) => field.typeName === row.original.subject?.__typename
          );

          return row.original.subject?.__typename && item ? (
            item?.typeName === "User" ? (
              <FormattedValue
                dataType={item?.dataType}
                data={row.original?.subject?.id}
              >
                {`${row.original?.subject?.firstName} ${row.original?.subject?.lastName}`}
              </FormattedValue>
            ) : item?.typeName === "Dictionary" ? (
              <FormattedValue
                dataType={item?.dataType}
                data={row.original?.subject?.id}
              >
                {row.original?.subject?.name}
              </FormattedValue>
            ) : item?.typeName === "DictionaryValue" ? (
              <FormattedValue
                dataType={item?.dataType}
                data={
                  item?.show.accessor
                    ? row.original[item.show.accessor[0]]?.[
                        item.show.accessor[1]
                      ]
                    : row.original[item.id]
                }
              >
                {`${row.original?.subject?.name} (słownik: ${row.original?.subject?.dictionary?.name})`}
              </FormattedValue>
            ) : item?.typeName === "Case" ? (
              <FormattedValue
                dataType={item?.dataType}
                data={row?.original?.subject?.id}
                additionalData={row.original.subject?.isConductedElectronically}
              >
                {`${row.original?.subject?.number}`}
              </FormattedValue>
            ) : item.typeName === DOCUMENT_TYPENAMES.Document ||
              item.typeName === DOCUMENT_TYPENAMES.ContractAgreement ||
              item.typeName === DOCUMENT_TYPENAMES.RequestForm ||
              item.typeName ===
                DOCUMENT_TYPENAMES.FinancialAccountingDocument ||
              item.typeName === DOCUMENT_TYPENAMES.ContractPreparationRequest ||
              item.typeName === DOCUMENT_TYPENAMES.BillIssuanceRequest ||
              item.typeName === DOCUMENT_TYPENAMES.RequestFormNote ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.documentKind?.toLowerCase()}/${
                  row?.original?.subject?.type
                }/${row?.original?.subject?.id}`}
              >
                {item.typeName === DOCUMENT_TYPENAMES.RequestForm
                  ? row?.original?.subject?.documentNumber
                  : row?.original?.subject?.internalNumber}
              </FormattedValue>
            ) : item.typeName === "BudgetItem" ? (
              <FormattedValue>
                {`Pozycja budżetu ${row?.original?.subject?.budget?.name}`}
              </FormattedValue>
            ) : item.typeName === "RequestFormItem" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.requestForm?.documentKind?.toLowerCase()}/${
                  row?.original?.subject?.requestForm?.type
                }/${row?.original?.subject?.requestForm?.id}`}
              >
                {`Pozycja wniosku ${row?.original?.subject?.requestForm?.documentNumber}`}
              </FormattedValue>
            ) : item.typeName === "BankAccount" ? (
              <FormattedValue
                dataType={item.dataType}
                data={row?.original?.subject?.address?.id}
              >
                {`Konto bankowe adresu ${row?.original?.subject?.address?.name}`}
              </FormattedValue>
            ) : item.typeName === "DocumentFlowStep" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.documentFlow?.id}/step/${row?.original?.subject?.id}`}
              >
                {row?.original?.subject?.name}
              </FormattedValue>
            ) : item.typeName === "File" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.properties?.document?.documentKind?.toLowerCase()}/${getDocumentTypename(
                  row?.original?.properties?.document?.type
                )}/${row?.original?.properties?.document?.id}`}
              >
                {`Załączniki dokumentu ${row?.original?.properties?.document?.internalNumber}`}
              </FormattedValue>
            ) : item.typeName === "ShipmentRate" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.shipmentContractId}/${row?.original?.subject?.id}`}
              >
                {row?.original?.subject?.name}
              </FormattedValue>
            ) : item.typeName === "DocumentParcel" ? (
              <FormattedValue
                dataType={item.dataType}
                data={row?.original?.subject?.id}
              >
                {`List nadany dokumentu ${row?.original?.subject?.document?.internalNumber}`}
              </FormattedValue>
            ) : item.typeName === "FinancialAccountingDocumentItem" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.financialAccountingDocument?.documentKind?.toLowerCase()}/${
                  row?.original?.subject?.financialAccountingDocument?.type
                }/${row?.original?.subject?.financialAccountingDocument?.id}`}
              >
                {`Pozycja ${row?.original?.subject?.financialAccountingDocument?.internalNumber}`}
              </FormattedValue>
            ) : item.typeName === "DocumentFlowAction" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.documentFlowId}/action/${row?.original?.subject?.id}`}
              >
                {row?.original?.subject?.label}
              </FormattedValue>
            ) : item.typeName === "DivisorItem" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.divisorTemplateId}/${row?.original?.subject?.id}`}
              >
                {row?.original?.subject?.name}
              </FormattedValue>
            ) : item.typeName === "PaymentItem" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.paymentId}`}
              >
                {`Pozycja polecenia płatności ${row?.original?.subject?.payment?.internalNumber}`}
              </FormattedValue>
            ) : item.typeName === "DocumentPickup" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.id}`}
              >
                {`Wydanie dokumentów numer ${row?.original?.subject?.id}`}
              </FormattedValue>
            ) : item.typeName === "Barcode" ? (
              <FormattedValue
                dataType={item.dataType}
                data={`${row?.original?.subject?.id}`}
              >
                Kod kreskowy
              </FormattedValue>
            ) : (
              // JRWA, OrganizationalUnit, Position, Role, Address, BudgetCategory, DocumentFlow, ShipmentContract, Currency
              // Payment, InternalAccount, DivisorTemplate, FinancialPlan
              <FormattedValue
                dataType={item?.dataType}
                data={row?.original?.subject?.id}
              >
                {item?.show.accessor
                  ? row.original[item?.show.accessor[0]]?.[
                      item?.show.accessor[1]
                    ]
                  : row.original[item?.id]}
              </FormattedValue>
            )
          ) : (
            <FormattedValue></FormattedValue>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Data
          </SortingTableHeader>
        ),
        accessor: "createdAt",
        Cell: ({ cell }: { cell: TableCell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="datetime">{cell.value}</FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={() => history.push(`/activity-logs/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters]
  );

  return (
    <article>
      <Header
        title="Logi aktywności"
        recordsNum={data?.activityLogs?.totalCount}
        labels={["Lista"]}
        actions={[
          {
            label: "Eksport do pliku XLSX",
            icon: TableExport,
            color: theme.colors.primary,
            onClick: () => exportActivityLogsToXlsx(),
            permission: checkPermission(PERMISSIONS.activityLog.read),
          },
        ]}
      />
      <Filters
        filters={ACTIVITY_LOGS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<ActivityLog>
              columns={columns}
              data={data?.activityLogs?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
