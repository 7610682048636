import { ApolloError } from "@apollo/client";
import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import React, { FormEventHandler, ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { SubstitutionsField } from "../containers/Substitutions/substitutions.form";
import { useAuth } from "../contexts/auth-context";
import { useLoading } from "../contexts/loading-context";
import { Field, FIELDS, FieldsGroup } from "../fields.d";
import { formValidation } from "../utils/formValidation";
import Cell from "./cell";
import { ControlledDatePicker } from "./date-picker";
import FormControl from "./form-control";
import Grid from "./grid";
import { ControlledInput } from "./input";
import { ControlledPermissionsTable } from "./permissions-table";
import {
  ControlledBarcodeFormatSelect,
  ControlledDictionaryValuesSelect,
  ControlledOutgoingDocumentsSelect,
  ControlledParcelSizesSelect,
  ControlledRolesMultiSelect,
  ControlledRolesSelect,
  ControlledUsersLiveSearchTableSelect,
  ControlledUsersSelect,
} from "./select";

type Props = {
  id: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  fields: FieldsGroup[];
  type: "edit" | "create";
  error?: ApolloError;
  isLoading?: boolean;
};

export default function FormRenderer({
  id,
  onSubmit,
  fields,
  type,
  error,
  isLoading,
}: Props): React.ReactElement {
  const [css] = useStyletron();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const { user } = useAuth();

  const isAdmin = user?.roles?.some(
    (role) => role.name === "Administrator systemu"
  );

  const { isFetching } = useLoading();

  function renderField(field: Field): ReactElement {
    switch (field.type) {
      case FIELDS.PermissionsTable:
        return (
          <ControlledPermissionsTable
            control={control}
            name={field.id}
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.requiredPermission,
              },
            })}
          />
        );

      case FIELDS.RolesMultiSelect:
        return (
          <ControlledRolesMultiSelect
            control={control}
            name={field.id}
            placeholder={field.label}
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      case FIELDS.RolesSelect:
        return (
          <ControlledRolesSelect
            control={control}
            name={field.id}
            placeholder={field.label}
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      case FIELDS.UsersSelect:
        return (
          <ControlledUsersSelect
            control={control}
            name={field.id}
            placeholder={field.label}
            disabled={field.disabled}
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      case FIELDS.MyOrganizationalUnitsUsersSelect:
        return (
          <ControlledUsersSelect
            fromMyOrganizationalUnits={!isAdmin}
            control={control}
            name={field.id}
            placeholder={field.label}
            disabled={field.disabled}
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      case FIELDS.DocumentsSelect:
        return (
          <ControlledOutgoingDocumentsSelect
            control={control}
            name={field.id}
            id={field.id}
            placeholder="Wybierz"
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      case FIELDS.DictionaryValuesSelect:
        return (
          <ControlledDictionaryValuesSelect
            dictionarySystemName={
              field.id === "position" ? "positions" : "countryCodes.alpha2.pl"
            }
            control={control}
            name={field.id}
            id={field.id}
            placeholder="Wybierz"
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      case FIELDS.ParcelSizesSelect:
        return (
          <ControlledParcelSizesSelect
            control={control}
            name={field.id}
            id={field.id}
            placeholder={field.placeholder}
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      case FIELDS.DatePicker:
        return (
          <ControlledDatePicker
            control={control}
            name={field.id}
            disabled={field.readonly}
            minDate={
              field.info === "substitutionEndDate"
                ? new Date(watch(SubstitutionsField.StartedAt))
                : field.minDate
            }
            {...(field.info === "substitutionStartDate" && {
              maxDate: watch(SubstitutionsField.EndedAt),
            })}
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      case FIELDS.UsersLiveSearchTableSelect:
        return (
          <ControlledUsersLiveSearchTableSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            multi
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      case FIELDS.BarcodeFormatSelect:
        return (
          <ControlledBarcodeFormatSelect
            control={control}
            name={field.id}
            id={field.id}
            placeholder={field.placeholder}
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );

      default:
        return (
          <ControlledInput
            control={control}
            name={field.id}
            placeholder={field.label}
            endEnhancer={field.endEnhancer}
            {...(field[type].required && {
              rules: {
                required: formValidation.messages.required,
              },
            })}
          />
        );
    }
  }

  return (
    <form id={id} onSubmit={onSubmit}>
      <Grid>
        {fields
          .filter((g) => g.fields.filter((f) => f[type].visible).length > 0)
          .map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f[type].visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl
                    label={item.label}
                    caption={item.caption}
                    required={item[type].required}
                    error={
                      ((errors as any)[item.id] &&
                        (errors as any)[item.id].message) ||
                      (error &&
                        error.graphQLErrors[0]?.extensions?.code ===
                          "INPUT_VALIDATION_ERROR" &&
                        error.graphQLErrors[0]?.extensions?.validationErrors
                          ?.find(
                            (vE: InputValidationError) =>
                              vE?.property === item.id
                          )
                          ?.errors.map((message: string) => (
                            <div
                              key="error"
                              className={css({
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              })}
                            >
                              {message}
                              <span
                                className={css({
                                  color: "#999",
                                  marginLeft: "auto",
                                  marginRight: "5px",
                                })}
                              >
                                Walidacja serwera
                              </span>
                              <AlertOctagon color="#999" size={12} />
                            </div>
                          ))[0])
                    }
                    disabled={isLoading}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="32px" width="100%" animation />
                    ) : (
                      renderField(item)
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
      </Grid>
    </form>
  );
}
