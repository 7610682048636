import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { StatefulPopover } from "baseui/popover";
import { LabelSmall } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Cloud,
  Eye,
  FileExport,
  FileImport,
  FileSymlink,
  Paperclip,
  ShareOff,
} from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button, { ForwardedButton } from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import { FieldName } from "../../../components/documents-table";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import { MemoizedStatefulTableV8 } from "../../../components/stateful-table-v8";
import Tooltip from "../../../components/tooltip";
import { DOCUMENT_KINDS } from "../../../constants";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { BasicFilter, FiltersState } from "../../../filters";
import { checkDocumentPermissionToShow } from "../../../utils/check-document-permission";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { Case } from "../../Cases/cases";
import { CASES_UNSHARE } from "../../Cases/cases.gql";
import { Document } from "../../Documents/documents";
import { DOCUMENTS_UNSHARE } from "../../Documents/documents.gql";
import {
  Assignment,
  AssignmentStatus,
  AssignmentType,
  FOLDERS_NAME,
} from "../folders.d";
import { FOLDERS_FILTERS_SENT } from "../folders.filters";
import { FOLDERS_SENT_ASSIGNMENTS } from "../folders.gql";

export default function FoldersSent(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const { folder } = useParams<{
    folder: string;
  }>();

  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [filters, setFilters] = useState<FiltersState>();

  const history = useHistory();
  const location = useLocation();
  const { findValue } = useDictionaries();

  const { enqueueSnackbar } = useSnackbar();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
    setIsLoading,
  } = useLoading();

  const { refetch, data, loading, error, networkStatus } = useQuery(
    FOLDERS_SENT_ASSIGNMENTS,
    {
      variables: {
        pageSize: pageSize,
        offset: (currentPage - 1) * pageSize,
        ...(sortBy &&
          sortDirection && {
            sorting: {
              field: sortBy,
              direction: sortDirection,
            },
          }),
        filter: {
          and: [
            {
              type: {
                eq:
                  folder === "shared"
                    ? AssignmentType.Share
                    : AssignmentType.Dispatch,
              },
            },
            {
              ...(filters && (translateFiltersState(filters) as BasicFilter[])),
            },
          ],
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [unshareCase] = useMutation(CASES_UNSHARE);

  const submitUnshareCase = async ({
    caseId,
    assignmentId,
  }: {
    caseId: number;
    assignmentId: number;
  }) => {
    setIsLoading(true);

    try {
      await unshareCase({
        variables: {
          caseUnshareInput: {
            caseId,
            assignmentId,
          },
        },
      });

      enqueueSnackbar({
        message: "Zakończono udostępnianie",
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [unshareDocument] = useMutation(DOCUMENTS_UNSHARE);

  const submitUnshareDocument = async ({
    documentId,
    assignmentId,
  }: {
    documentId: number;
    assignmentId: number;
  }) => {
    setIsLoading(true);

    try {
      await unshareDocument({
        variables: {
          documentUnshareInput: {
            documentId,
            assignmentId,
          },
        },
      });

      enqueueSnackbar({
        message: "Zakończono udostępnianie",
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: "type",
        header: "Działanie",
        accessorKey: "type",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return row.original?.type === AssignmentType.Share
            ? "Udostępnienie"
            : row.original?.type === AssignmentType.Initial
            ? "Utworzenie"
            : row.original?.type === AssignmentType.Flow
            ? "Obieg zdefiniowany"
            : `Przekazanie${
                row.original?.isAssessmentRequested ? " do akceptacji" : ""
              }`;
        },
      },
      {
        header: "Dla",
        id: "assigneeId",
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue
            dataType={"model:users"}
            data={row.original?.assignee?.id}
          >
            {`${row.original?.assignee?.firstName} ${row.original?.assignee?.lastName}`}
          </FormattedValue>
        ),
      },
      {
        header: "Numer w składzie chronologicznym",
        id: "sequenceNumber",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue>
              {(row.original?.primaryAssignable as Document)?.sequenceNumber}
            </FormattedValue>
          );
        },
      },
      {
        id: "__typeName",
        header: "Typ",
        accessorKey: "__typeName",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue
              loadingIfUndefined={
                !!(
                  (row.original?.primaryAssignable as Document)?.documentType &&
                  (row.original?.primaryAssignable as Document)
                    ?.documentType !== "undefined"
                )
              }
            >
              {row.original?.primaryAssignable?.__typename === "Case"
                ? "Sprawa"
                : (row.original?.primaryAssignable as Document)?.documentType &&
                  (row.original?.primaryAssignable as Document)?.documentType
                ? findValue(
                    (row.original?.primaryAssignable as Document)
                      ?.documentType as string
                  )
                : null}
            </FormattedValue>
          );
        },
      },
      {
        id: "caseId",
        header: "Znak sprawy",
        accessorKey: "case.number",
        cell: ({ row }: { row: Row<Assignment> }) => {
          const typeName = row.original?.primaryAssignable?.__typename;

          const accessor: Case | undefined =
            typeName === "Case"
              ? (row.original?.primaryAssignable as Case)
              : (row.original?.primaryAssignable as Document)?.case;

          return accessor ? (
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(
                  `/cases/${accessor?.id}`,
                  typeName === "Case" && {
                    assignmentId: row.original?.parentId,
                  }
                );
              }}
              href={`/cases/${accessor?.id}`}
              $style={{
                color: `${
                  accessor?.isConductedElectronically
                    ? theme.colors.positive
                    : theme.colors.negative
                } !important`,
              }}
            >
              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "5px",
                })}
              >
                <span
                  className={css({
                    whiteSpace: "nowrap",
                  })}
                >
                  {accessor?.number}
                </span>

                <Tooltip
                  content={
                    accessor?.isConductedElectronically
                      ? "Sprawa prowadzona elektronicznie"
                      : "Sprawa prowadzona tradycyjnie"
                  }
                >
                  <span>
                    {accessor?.isConductedElectronically ? (
                      <Cloud
                        size={14}
                        className={css({
                          verticalAlign: "middle",
                          display: "inline",
                          color: theme.colors.positive,
                        })}
                      />
                    ) : (
                      <Paperclip
                        size={14}
                        className={css({
                          verticalAlign: "middle",
                          display: "inline",
                          color: theme.colors.negative,
                        })}
                      />
                    )}
                  </span>
                </Tooltip>
              </div>
            </StyledLink>
          ) : (
            <FormattedValue />
          );
        },
      },
      {
        header: "Numer",
        accessorKey: "internalNumber",
        cell: ({ row }: { row: Row<Assignment> }) => {
          const typeName = row.original?.primaryAssignable?.__typename;

          const documentKind = (row.original
            ?.primaryAssignable as Document)?.documentKind?.toLowerCase();
          const documentTypename = (row.original?.primaryAssignable as Document)
            ?.type;

          return typeName === "Case" ? (
            <FormattedValue
              textWhenEmpty={typeName === "Case" ? "N/D" : undefined}
            />
          ) : (
            <StyledLink
              {...((row.original?.primaryAssignable as Document)
                ?.documentType &&
                (checkDocumentPermissionToShow(
                  (row.original?.primaryAssignable as Document)
                    ?.documentType as string
                ) ||
                  checkPermission(PERMISSIONS.document.readAll)) && {
                  onClick: (event: MouseEvent) => {
                    event.preventDefault();
                    history.push(
                      `/documents/${documentKind}/${documentTypename}/${row.original?.primaryAssignable.id}`,
                      {
                        assignmentId: row.original?.parentId,
                      }
                    );
                  },
                  href: `/documents/${documentKind}/${documentTypename}/${row.original?.primaryAssignable.id}`,
                })}
            >
              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "5px",
                })}
              >
                <span className={css({ whiteSpace: "nowrap" })}>
                  {
                    (row.original?.primaryAssignable as Document)
                      ?.internalNumber
                  }
                </span>

                <Tooltip
                  {...((row.original?.primaryAssignable as Document)
                    ?.documentKind === DOCUMENT_KINDS.Incoming && {
                    content: "Przychodzący",
                  })}
                  {...((row.original?.primaryAssignable as Document)
                    ?.documentKind === DOCUMENT_KINDS.Outgoing && {
                    content: "Wychodzący",
                  })}
                  {...((row.original?.primaryAssignable as Document)
                    ?.documentKind === DOCUMENT_KINDS.Internal && {
                    content: "Wewnętrzny",
                  })}
                  placement="bottomLeft"
                >
                  <span>
                    {(row.original?.primaryAssignable as Document)
                      ?.documentKind === DOCUMENT_KINDS.Incoming && (
                      <FileImport size={18} />
                    )}

                    {(row.original?.primaryAssignable as Document)
                      ?.documentKind === DOCUMENT_KINDS.Outgoing && (
                      <FileExport size={18} />
                    )}

                    {(row.original?.primaryAssignable as Document)
                      ?.documentKind === DOCUMENT_KINDS.Internal && (
                      <FileSymlink size={18} />
                    )}
                  </span>
                </Tooltip>
              </div>
            </StyledLink>
          );
        },
      },
      {
        header: "Status",
        id: "status",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue dataType="assignment-status">
              {row.original?.status}
            </FormattedValue>
          );
        },
      },
      {
        header: "Komentarz",
        id: "assignerComment",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue
              $style={{
                whiteSpace: "pre-wrap",
                width: "max-content",
                display: "flex",
              }}
            >
              {row.original?.assignerComment}
            </FormattedValue>
          );
        },
      },
      {
        header: "Komentarz zwrotny",
        id: "assigneeComment",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue
              $style={{
                whiteSpace: "pre-wrap",
                width: "max-content",
                display: "flex",
              }}
            >
              {row.original?.assigneeComment}
            </FormattedValue>
          );
        },
      },
      {
        header: () => (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
          >
            Data wysłania
          </SortingTableHeader>
        ),
        id: "createdAt",
        meta: { text: "Data wysłania" },
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue dataType="datetime-relative">
              {row.original?.createdAt}
            </FormattedValue>
          );
        },
      },
      {
        id: "name",
        header: "Tytuł",
        accessor: "name",
        disableFilters: true,
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue>
            {row.original?.primaryAssignable?.name}
          </FormattedValue>
        ),
      },
      {
        id: "projectNumber",
        header: "Numer projektu",
        accessor: "projectNumber",
        disableFilters: true,
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue
            {...(row.original?.primaryAssignable?.__typename === "Document" && {
              textWhenEmpty: "N/D",
            })}
          >
            {(row.original?.primaryAssignable as Case)?.projectNumber}
          </FormattedValue>
        ),
      },
      {
        id: "documentNumber",
        header: "Numer dokumentu",
        accessor: "documentNumber",
        disableFilters: true,
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue
            {...(row.original?.primaryAssignable?.__typename === "Case" && {
              textWhenEmpty: "N/D",
            })}
          >
            {(row.original?.primaryAssignable as Document)?.documentNumber}
          </FormattedValue>
        ),
      },
      {
        id: "additionalCode",
        header: "Kod E+/ACC/QL/Inne",
        accessorKey: "additionalCode",
        disableFilters: true,
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue
            {...(row.original?.primaryAssignable?.__typename === "Case" && {
              textWhenEmpty: "N/D",
            })}
          >
            {(row.original?.primaryAssignable as Document)?.additionalCode}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        cell: ({ row }: { row: Row<Assignment> }) => {
          const typeName = row.original?.primaryAssignable?.__typename;

          const documentKind = (row.original
            ?.primaryAssignable as Document)?.documentKind?.toLowerCase();
          const documentTypename = (row.original?.primaryAssignable as Document)
            ?.type;

          return (
            <div
              className={css({
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              })}
            >
              {folder === "shared" &&
                row.original?.type === AssignmentType.Share &&
                row.original?.status !== AssignmentStatus.Finished && (
                  <StatefulPopover
                    overrides={{
                      Inner: {
                        style: {
                          borderTopLeftRadius: theme.borders.radius200,
                          borderTopRightRadius: theme.borders.radius200,
                          borderBottomRightRadius: theme.borders.radius200,
                          borderBottomLeftRadius: theme.borders.radius200,
                          paddingTop: theme.sizing.scale200,
                          paddingBottom: theme.sizing.scale200,
                          paddingRight: theme.sizing.scale300,
                          paddingLeft: theme.sizing.scale300,
                          backgroundColor: "white",
                        },
                      },
                      Body: {
                        style: {
                          borderTopLeftRadius: theme.borders.radius200,
                          borderTopRightRadius: theme.borders.radius200,
                          borderBottomRightRadius: theme.borders.radius200,
                          borderBottomLeftRadius: theme.borders.radius200,
                          marginRight: "10px",
                          zIndex: 22,
                        },
                      },
                      Arrow: {
                        style: {
                          backgroundColor: "white",
                        },
                      },
                    }}
                    content={({ close }) => (
                      <Block
                        padding="scale200"
                        backgroundColor="white"
                        display="flex"
                        alignItems="center"
                      >
                        <LabelSmall marginRight="8px">
                          Czy chcesz zakończyć to udostępnienie?
                        </LabelSmall>
                        <Button
                          kind="secondary"
                          size={"mini"}
                          onClick={() =>
                            row.original.primaryAssignable.__typename === "Case"
                              ? submitUnshareCase({
                                  assignmentId: row.original?.id,
                                  caseId: row.original?.primaryAssignable?.id,
                                })
                              : submitUnshareDocument({
                                  assignmentId: row.original?.id,
                                  documentId:
                                    row.original?.primaryAssignable.id,
                                })
                          }
                          $style={{
                            backgroundColor: theme.colors.negative400,
                            color: "white",
                            ":hover": {
                              backgroundColor: theme.colors.negative500,
                            },
                          }}
                        >
                          Zakończ
                        </Button>
                        <Button
                          kind="secondary"
                          size={"mini"}
                          onClick={() => close()}
                          $style={{
                            marginLeft: "5px",
                          }}
                        >
                          Anuluj
                        </Button>
                      </Block>
                    )}
                    showArrow
                  >
                    <ForwardedButton
                      type="reset"
                      kind="primary"
                      size={"mini"}
                      startEnhancer={<ShareOff size={14} />}
                    />
                  </StatefulPopover>
                )}

              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                $style={{ marginLeft: "6px" }}
                disabled={
                  typeName === "Document" &&
                  !!(row.original?.primaryAssignable as Document)
                    ?.documentType &&
                  !checkDocumentPermissionToShow(
                    (row.original?.primaryAssignable as Document)
                      ?.documentType as string
                  ) &&
                  !checkPermission(PERMISSIONS.document.readAll)
                }
                onClick={() =>
                  typeName === "Document"
                    ? history.push(
                        `/documents/${documentKind}/${documentTypename}/${row.original?.primaryAssignable.id}`,
                        {
                          assignmentId: row.original?.parentId,
                        }
                      )
                    : history.push(
                        `/cases/${row.original?.primaryAssignable.id}`,
                        {
                          assignmentId: row.original?.parentId,
                        }
                      )
                }
                startEnhancer={<Eye size={14} />}
              />
            </div>
          );
        },
      },
    ],
    [data, sortBy, sortDirection, findValue, filters]
  );

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.receivedAssignments || data?.sentAssignments) {
      setTimeout(() => refetch(), 200);
      setIsPartialFetching(true);
    } else setIsFetching(true);
  }, [folder, location.key]);

  useEffect(() => {
    if (data?.receivedAssignments || data?.sentAssignments) {
      setIsFetching(false);
      setIsPartialFetching(false);
    }
    if ((data?.receivedAssignments || data?.sentAssignments)?.totalCount >= 0)
      setTotalCount(
        (data?.receivedAssignments || data?.sentAssignments)?.totalCount
      );
  }, [data, networkStatus]);

  return (
    <article>
      <Header
        title={`${FOLDERS_NAME.get(folder)}`}
        recordsNum={
          (data?.receivedAssignments || data?.sentAssignments)?.totalCount
        }
        labels={["Lista"]}
      />

      <Filters
        filters={FOLDERS_FILTERS_SENT}
        state={filters}
        setState={setFilters}
      />

      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <MemoizedStatefulTableV8
              data={(data?.receivedAssignments || data?.sentAssignments)?.nodes}
              columns={columns as ColumnDef<any>[]}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
