import { gql } from "@apollo/client";

export const BARCODES_EDIT = gql`
  mutation EditBarcode($barcodeUpdateInput: BarcodeUpdateInput!) {
    barcodeUpdate(barcodeUpdateInput: $barcodeUpdateInput) {
      id
    }
  }
`;

export const BARCODES_SHOW = gql`
  query($id: Int!) {
    barcode(id: $id) {
      id
      codeFormat
      width
      height
      stickerWidth
      stickerHeight
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`;
