import { useMemo } from "react";

import { Document } from "../../containers/Documents/documents";

export const useFormFieldDisable = (
  document?: Pick<
    Document,
    "currentStatus" | "isEditable" | "isSequenceNumberEditable"
  >
): ((fieldName: string) => boolean) => {
  const shouldDisableField = useMemo<(fieldName: string) => boolean>(
    () => (fieldName: string): boolean => {
      if (!document) return false;

      const shouldDisableByFlowStep: boolean =
        !!document.currentStatus?.documentFlowStep &&
        !document.currentStatus.documentFlowStep?.allowedDocumentFields?.includes(
          fieldName
        ) &&
        !(
          document.currentStatus.documentFlowStep?.isAllowedToEditAllFields ??
          false
        );

      if (fieldName === "sequenceNumber") {
        const canEditSequenceNumber =
          !!(document.isSequenceNumberEditable ?? false) &&
          !shouldDisableByFlowStep;

        return !canEditSequenceNumber;
      }

      return shouldDisableByFlowStep || !(document.isEditable ?? false);
    },
    [document]
  );

  return shouldDisableField;
};
